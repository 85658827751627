import React from 'react';
import pdf from '../Deck.pdf';

interface IDeck {}

const Deck: React.FC<IDeck> = () => {
  return (
    <section className="py-20 px-4">
      <div className="container mx-auto flex flex-col justify-center items-center">
        <a
          href={pdf}
          target = "_blank"
          className="w-full lg:w-auto bg-blue color-light-cyan p-6 rounded-lg mt-10 flex flex-row justify-center items-center font-semibold text-lg lg:text-2xl" rel="noreferrer"
        >
          Visiona la presentazione
        </a>
      </div>
    </section>
  );
};

export default Deck;

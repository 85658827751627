export default {
  v: '5.5.8',
  fr: 25,
  ip: 0,
  op: 91,
  w: 200,
  h: 250,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'lapicero/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [-4],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 6,
              s: [0],
              e: [-4],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 12,
              s: [-4],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 18,
              s: [0],
              e: [-6.857],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 23,
              s: [-6.857],
              e: [-4],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 25,
              s: [-4],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 32,
              s: [0],
              e: [-4],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 39,
              s: [-4],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46,
              s: [0],
              e: [-4],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 53,
              s: [-4],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 61,
              s: [0],
              e: [-4],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 69,
              s: [-4],
              e: [0],
            },
            { t: 78 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [59, 105.5, 0],
              e: [81.002, 105.5, 0],
              to: [3.521, 0, 0],
              ti: [-8.303, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 10,
              s: [81.002, 105.5, 0],
              e: [117.078, 105.967, 0],
              to: [12.076, 0, 0],
              ti: [-4.186, -0.785, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 24,
              s: [117.078, 105.967, 0],
              e: [114, 107, 0],
              to: [1.247, 0.234, 0],
              ti: [1.452, -0.467, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 25,
              s: [114, 107, 0],
              e: [52, 117.5, 0],
              to: [-49.72, 16, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 32,
              s: [52, 117.5, 0],
              e: [117, 117.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 52,
              s: [117, 117.5, 0],
              e: [58.5, 127.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 58,
              s: [58.5, 127.5, 0],
              e: [104, 127.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 78 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [89, 89, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.105, 0.106],
                    [0, 0],
                    [-0.109, -0.108],
                    [0, 0],
                    [0.102, -0.103],
                    [0, 0],
                    [0.104, 0.105],
                  ],
                  o: [
                    [-0.109, -0.109],
                    [0, 0],
                    [0.103, -0.102],
                    [0, 0],
                    [0.104, 0.105],
                    [0, 0],
                    [-0.106, 0.106],
                    [0, 0],
                  ],
                  v: [
                    [-6.207, -3.508],
                    [-6.219, -3.893],
                    [-3.889, -6.223],
                    [-3.502, -6.214],
                    [6.213, 3.502],
                    [6.222, 3.889],
                    [3.893, 6.219],
                    [3.508, 6.207],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.898000021542, 0.913999968884, 0.941000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [169.536, 81.423], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.168, 1.819],
                    [0.987, 0.958],
                    [2.208, 2.113],
                    [0.278, -0.279],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.279, -0.279],
                    [-2.119, -2.215],
                    [-0.959, -0.989],
                    [-1.823, 1.163],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-16.765, 16.524],
                    [-15.595, 17.962],
                    [-15.326, 17.691],
                    [-11.787, 21.01],
                    [20.773, -11.659],
                    [16.128, -16.37],
                    [11.422, -21.01],
                    [-20.772, 11.476],
                    [-17.934, 15.083],
                    [-18.203, 15.354],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [186.526, 64.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.925, 5.098],
                    [0.008, 0.006],
                    [0.011, 0.012],
                    [0.399, -0.399],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.4, -0.399],
                    [-0.013, -0.014],
                    [-0.008, -0.01],
                    [-5.099, -4.925],
                    [0, 0],
                    [-0.005, 0.006],
                    [0, 0],
                  ],
                  v: [
                    [-3.46, 3.456],
                    [-3.431, 3.487],
                    [-3.421, 3.487],
                    [1.551, 7.831],
                    [2.904, -2.848],
                    [2.874, -2.878],
                    [2.847, -2.906],
                    [-7.829, -1.548],
                    [-3.485, 3.422],
                    [-3.488, 3.43],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.898000021542, 0.913999968884, 0.941000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [204.85, 45.867], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.583, -0.555],
                    [0, 0],
                    [0, 0],
                    [2.284, -3.445],
                  ],
                  o: [
                    [3.435, -2.277],
                    [0, 0],
                    [0, 0],
                    [3.242, 3.368],
                  ],
                  v: [
                    [-20.064, 25.779],
                    [25.589, -16.461],
                    [16.298, -25.779],
                    [-25.589, 20.319],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [147.532, 103.321], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.178, -0.155],
                    [-1.275, 1.092],
                    [0, 0],
                    [0.806, -1.743],
                  ],
                  o: [
                    [2.85, -1.093],
                    [0, 0],
                    [-1.814, 1.935],
                    [1.297, 1.275],
                  ],
                  v: [
                    [-4.37, 6.561],
                    [6.601, -1.008],
                    [0.994, -6.562],
                    [-6.601, 4.376],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.791999966491, 0.811999990426, 0.842999985639, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [121.096, 129.944], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.367, 0.367],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.359, -0.359],
                    [0, 0],
                  ],
                  v: [
                    [1.595, 0.633],
                    [-1.236, 1.288],
                    [-0.574, -1.654],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [115.179, 135.823], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.904, -0.045],
                    [2.702, -2.72],
                    [4.872, -11.119],
                    [-0.791, 0.162],
                    [0.095, 0.789],
                    [-0.338, 1.153],
                    [-0.899, 0.425],
                    [-0.477, -0.659],
                  ],
                  o: [
                    [-2.915, -1.327],
                    [-0.733, 0.031],
                    [-1.919, 1.933],
                    [-0.31, 0.933],
                    [1.014, -0.199],
                    [-0.1, -0.782],
                    [0.333, -1.162],
                    [0.911, -0.429],
                    [2.602, -3.503],
                  ],
                  v: [
                    [15.374, -13.142],
                    [10.119, -14.769],
                    [4.559, -11.672],
                    [-15.065, 13.503],
                    [-13.771, 14.652],
                    [-7.912, 10.036],
                    [-8.221, 6.812],
                    [6.666, -7.396],
                    [8.733, -6.256],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [182.386, 58.095], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'linea 3/@1xilustración 1 contornos 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [100, 147.5, 0], ix: 2 },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [89, 89, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [68.639, 108.079],
                [68.382, 118.893],
                [122.128, 118.893],
                [121.963, 110.537],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Máscara 1',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0]],
                  o: [[0, 0]],
                  v: [[234.815, 94.315]],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Forma 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-34.935, 1.417],
                    [34.934, 1.417],
                    [34.934, -1.418],
                    [-34.935, -1.418],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 58,
                    s: [26.758, 113.855],
                    e: [103.758, 113.855],
                    to: [12.833, 0],
                    ti: [-12.833, 0],
                  },
                  { t: 81 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'linea 3/@1xilustración 1 contornos 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [100, 136.5, 0], ix: 2 },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [89, 89, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [68.639, 108.079],
                [68.382, 118.893],
                [141.299, 119.174],
                [141.556, 108.36],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Máscara 1',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0]],
                  o: [[0, 0]],
                  v: [[234.815, 94.315]],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Forma 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-34.935, 1.417],
                    [34.934, 1.417],
                    [34.934, -1.418],
                    [-34.935, -1.418],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 28,
                    s: [26.758, 113.855],
                    e: [103.758, 113.855],
                    to: [12.833, 0],
                    ti: [-12.833, 0],
                  },
                  { t: 53 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'linea 3/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [100, 125, 0], ix: 2 },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [89, 89, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [68.639, 108.079],
                [68.382, 118.893],
                [141.299, 119.174],
                [141.556, 108.36],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Máscara 1',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0]],
                  o: [[0, 0]],
                  v: [[234.815, 94.315]],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Forma 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-34.935, 1.417],
                    [34.934, 1.417],
                    [34.934, -1.418],
                    [-34.935, -1.418],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [26.758, 113.855],
                    e: [103.758, 113.855],
                    to: [12.833, 0],
                    ti: [-12.833, 0],
                  },
                  { t: 23 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'cuadrado/@1xilustración 1 contornos 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [112, 125, 0],
              e: [79, 125, 0],
              to: [-5.5, 0, 0],
              ti: [5.5, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 24,
              s: [79, 125, 0],
              e: [79, 125, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 32,
              s: [79, 125, 0],
              e: [111, 125, 0],
              to: [5.333, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 48,
              s: [111, 125, 0],
              e: [79, 125, 0],
              to: [0, 0, 0],
              ti: [5.333, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 71,
              s: [79, 125, 0],
              e: [79, 125, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 77,
              s: [79, 125, 0],
              e: [111, 125, 0],
              to: [5.333, 0, 0],
              ti: [-5.333, 0, 0],
            },
            { t: 91 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [0.211, 75.274],
                [0.211, 107.673],
                [32.612, 107.673],
                [32.612, 75.274],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Máscara 1',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 3.887],
                    [0, 0],
                    [-3.889, 0],
                    [0, 0],
                    [0, -3.888],
                    [0, 0],
                    [3.888, 0],
                  ],
                  o: [
                    [-3.889, 0],
                    [0, 0],
                    [0, -3.888],
                    [0, 0],
                    [3.888, 0],
                    [0, 0],
                    [0, 3.887],
                    [0, 0],
                  ],
                  v: [
                    [-9.13, 16.2],
                    [-16.201, 9.132],
                    [-16.201, -9.132],
                    [-9.13, -16.199],
                    [9.131, -16.199],
                    [16.201, -9.132],
                    [16.201, 9.132],
                    [9.131, 16.2],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [16.411, 91.473], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'lupa/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 27,
              s: [0],
              e: [-5],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 31,
              s: [-5],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 35,
              s: [0],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 74,
              s: [0],
              e: [-5],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 78,
              s: [-5],
              e: [0],
            },
            { t: 82 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [101.5, 124, 0], ix: 2 },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [89, 89, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.32, 0.005],
                    [0.012, 2.762],
                    [-0.934, 0.935],
                    [-1.321, -0.005],
                    [-0.013, -2.76],
                    [0.934, -0.934],
                  ],
                  o: [
                    [-2.761, -0.015],
                    [-0.007, -1.32],
                    [0.933, -0.935],
                    [2.761, 0.017],
                    [0.007, 1.321],
                    [-0.933, 0.935],
                  ],
                  v: [
                    [-1.733, 3.25],
                    [-6.739, -1.763],
                    [-5.292, -5.287],
                    [-1.77, -6.74],
                    [3.237, -1.727],
                    [1.789, 1.798],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.247, 0.234],
                    [0, 0],
                    [0.006, 1.322],
                    [3.451, 0.019],
                    [1.167, -1.168],
                    [-0.01, -1.651],
                    [-3.451, -0.021],
                    [-1.067, 0.79],
                    [0, 0],
                    [0, 0],
                    [-0.331, 0.086],
                    [-0.086, 0.331],
                  ],
                  o: [
                    [0, 0],
                    [0.782, -1.066],
                    [-0.015, -3.451],
                    [-1.651, -0.008],
                    [-1.168, 1.169],
                    [0.015, 3.451],
                    [1.327, 0.005],
                    [0, 0],
                    [0, 0],
                    [0.236, 0.246],
                    [0.33, -0.086],
                    [0.084, -0.331],
                  ],
                  v: [
                    [7.649, 6.318],
                    [3.288, 1.955],
                    [4.483, -1.722],
                    [-1.774, -7.987],
                    [-6.177, -6.174],
                    [-7.986, -1.767],
                    [-1.729, 4.5],
                    [1.959, 3.292],
                    [1.963, 3.289],
                    [6.32, 7.648],
                    [7.238, 7.909],
                    [7.912, 7.234],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Combinar trazados 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [16.649, 91.508], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'cuadrado/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [112, 125, 0], ix: 2 },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [0.211, 75.274],
                [0.211, 107.673],
                [32.612, 107.673],
                [32.612, 75.274],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Máscara 1',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 3.887],
                    [0, 0],
                    [-3.889, 0],
                    [0, 0],
                    [0, -3.888],
                    [0, 0],
                    [3.888, 0],
                  ],
                  o: [
                    [-3.889, 0],
                    [0, 0],
                    [0, -3.888],
                    [0, 0],
                    [3.888, 0],
                    [0, 0],
                    [0, 3.887],
                    [0, 0],
                  ],
                  v: [
                    [-9.13, 16.2],
                    [-16.201, 9.132],
                    [-16.201, -9.132],
                    [-9.13, -16.199],
                    [9.131, -16.199],
                    [16.201, -9.132],
                    [16.201, 9.132],
                    [9.131, 16.2],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [16.411, 91.473], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'cruz/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [112.5, 124.781, 0],
              e: [112.5, 121, 0],
              to: [0, -0.63, 0],
              ti: [0, -0.703, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 15,
              s: [112.5, 121, 0],
              e: [112.5, 129, 0],
              to: [0, 0.703, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 32,
              s: [112.5, 129, 0],
              e: [112.5, 121, 0],
              to: [0, 0, 0],
              ti: [0, 0.167, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 49,
              s: [112.5, 121, 0],
              e: [112.5, 128, 0],
              to: [0, -0.167, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 67,
              s: [112.5, 128, 0],
              e: [112.5, 121, 0],
              to: [0, 0, 0],
              ti: [0, 1.167, 0],
            },
            { t: 85 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [100.516, 100.516, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [1.302, 0],
                    [0, 0],
                    [0, -1.302],
                    [0, 0],
                    [0, 0],
                    [0, -1.304],
                    [0, 0],
                    [-1.303, 0],
                    [0, 0],
                    [0, 0],
                    [-1.303, 0],
                    [0, 0],
                    [0, 1.302],
                    [0, 0],
                    [0, 0],
                    [0, 1.303],
                    [0, 0],
                    [1.303, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, -1.302],
                    [0, 0],
                    [-1.303, 0],
                    [0, 0],
                    [0, 0],
                    [-1.303, 0],
                    [0, 0],
                    [0, 1.303],
                    [0, 0],
                    [0, 0],
                    [0, 1.302],
                    [0, 0],
                    [1.302, 0],
                    [0, 0],
                    [0, 0],
                    [1.303, 0],
                    [0, 0],
                    [0, -1.304],
                    [0, 0],
                  ],
                  v: [
                    [5.638, -5.638],
                    [5.638, -18.152],
                    [3.27, -20.52],
                    [-3.268, -20.52],
                    [-5.637, -18.152],
                    [-5.637, -5.638],
                    [-18.151, -5.638],
                    [-20.52, -3.269],
                    [-20.52, 3.268],
                    [-18.151, 5.637],
                    [-5.637, 5.637],
                    [-5.637, 18.152],
                    [-3.268, 20.52],
                    [3.27, 20.52],
                    [5.638, 18.152],
                    [5.638, 5.637],
                    [18.152, 5.637],
                    [20.52, 3.268],
                    [20.52, -3.269],
                    [18.152, -5.638],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [37.92, 34.92], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'circulo/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [112.5, 124.5, 0],
              e: [112.5, 121, 0],
              to: [0, -0.583, 0],
              ti: [0, -0.75, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 15,
              s: [112.5, 121, 0],
              e: [112.5, 129, 0],
              to: [0, 0.75, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 32,
              s: [112.5, 129, 0],
              e: [112.5, 121, 0],
              to: [0, 0, 0],
              ti: [0, 0.167, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 49,
              s: [112.5, 121, 0],
              e: [112.5, 128, 0],
              to: [0, -0.167, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 67,
              s: [112.5, 128, 0],
              e: [112.5, 121, 0],
              to: [0, 0, 0],
              ti: [0, 1.167, 0],
            },
            { t: 85 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [100.516, 100.516, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -19.286],
                    [19.286, 0],
                    [0, 19.286],
                    [-19.286, 0],
                  ],
                  o: [
                    [0, 19.286],
                    [-19.286, 0],
                    [0, -19.286],
                    [19.286, 0],
                  ],
                  v: [
                    [34.92, 0],
                    [0, 34.92],
                    [-34.92, 0],
                    [0, -34.92],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.898000021542, 0.913999968884, 0.941000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [37.92, 34.92], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'pastilla g/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [86.5, 125, 0],
          ix: 2,
          x: 'var $bm_rt;\nvar maxDev, spd, decay, t, x, y;\nmaxDev = 4;\nspd = 8;\ndecay = 1;\nt = $bm_sub(time, inPoint);\nx = $bm_sum($bm_transform.scale[0], $bm_div($bm_mul(maxDev, Math.sin($bm_mul(spd, t))), Math.exp($bm_mul(decay, t))));\ny = $bm_div($bm_mul($bm_transform.scale[0], $bm_transform.scale[1]), x);\n$bm_rt = [\n    x,\n    y\n];',
        },
        a: { a: 0, k: [123.5, 68, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-2.445, -1.245],
                    [0, 0],
                    [6.372, 3.188],
                    [1.037, -0.118],
                    [0, -0.661],
                  ],
                  o: [
                    [0.638, 0],
                    [6.709, 3.418],
                    [-3.97, -2.646],
                    [-1.037, -0.519],
                    [0, 0],
                    [0, 0.662],
                  ],
                  v: [
                    [-10.917, -5.565],
                    [-4.963, -3.581],
                    [12.24, 7.005],
                    [-6.947, -5.565],
                    [-10.917, -6.888],
                    [-12.24, -6.227],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [197.786, 112.113], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-2.238, 3.714],
                    [3.823, 2.305],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [3.823, 2.304],
                    [2.239, -3.715],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.688, 2.637],
                    [-1.527, 8.76],
                    [9.449, 6.208],
                    [6.58, -4.691],
                    [-4, -11.064],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.898000021542, 0.913999968884, 0.941000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [204.942, 123.32], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-2.238, 3.714],
                    [3.825, 2.305],
                    [0, 0],
                    [0.278, 0.168],
                    [2.247, -3.729],
                    [-3.441, -2.424],
                  ],
                  o: [
                    [0, 0],
                    [3.823, 2.305],
                    [2.24, -3.715],
                    [0, 0],
                    [-0.351, -0.208],
                    [-3.838, -2.312],
                    [-2.144, 3.559],
                    [0, 0],
                  ],
                  v: [
                    [-14.452, 0.56],
                    [6.263, 13.042],
                    [17.238, 10.49],
                    [14.368, -0.409],
                    [-5.446, -12.387],
                    [-6.523, -13.035],
                    [-17.333, -10.378],
                    [-14.953, 0.223],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [197.014, 118.957], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'pastilla p1/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [89, 127.5, 0],
          ix: 2,
          x: 'var $bm_rt;\nvar maxDev, spd, decay, t, x, y;\nmaxDev = 4;\nspd = 5;\ndecay = 1;\nt = $bm_sub(time, inPoint);\nx = $bm_sum($bm_transform.scale[0], $bm_div($bm_mul(maxDev, Math.sin($bm_mul(spd, t))), Math.exp($bm_mul(decay, t))));\ny = $bm_div($bm_mul($bm_transform.scale[0], $bm_transform.scale[1]), x);\n$bm_rt = [\n    x,\n    y\n];',
        },
        a: { a: 0, k: [119.5, 63, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.589, -1.255],
                    [0, 0],
                    [-4.152, 3.227],
                    [-0.767, 0.064],
                    [-0.095, -0.478],
                  ],
                  o: [
                    [-0.461, 0.093],
                    [-4.362, 3.443],
                    [2.489, -2.488],
                    [0.674, -0.525],
                    [0, 0],
                    [0.096, 0.479],
                  ],
                  v: [
                    [6.991, -5.648],
                    [2.969, -3.35],
                    [-7.948, 6.796],
                    [4.119, -5.074],
                    [6.799, -6.604],
                    [7.852, -6.317],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [183.949, 145.642], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.156, 2.364],
                    [-2.434, 2.22],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.433, 2.221],
                    [-2.157, -2.364],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [8.468, 0.343],
                    [2, 6.241],
                    [-6.311, 5.981],
                    [-5.809, -2.319],
                    [0.924, -8.462],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.898000021542, 0.913999968884, 0.941000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [180.657, 154.62], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.156, 2.364],
                    [-2.434, 2.221],
                    [0, 0],
                    [-0.176, 0.161],
                    [-2.164, -2.374],
                    [2.14, -2.252],
                  ],
                  o: [
                    [0, 0],
                    [-2.433, 2.22],
                    [-2.156, -2.365],
                    [0, 0],
                    [0.225, -0.202],
                    [2.443, -2.229],
                    [2.066, 2.265],
                    [0, 0],
                  ],
                  v: [
                    [10.542, -1.664],
                    [-2.644, 10.364],
                    [-10.955, 10.103],
                    [-10.453, 1.802],
                    [2.153, -9.731],
                    [2.839, -10.354],
                    [11.045, -9.995],
                    [10.855, -1.979],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [185.39, 150.421], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'pastilla p2/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [-15.5, 255.158, 0],
              e: [-12, 266.158, 0],
              to: [0.583, 1.833, 0],
              ti: [-0.583, -1.833, 0],
            },
            { t: 44 },
          ],
          ix: 2,
          x: 'var $bm_rt;\nvar maxDev, spd, decay, t, x, y;\nmaxDev = 4;\nspd = 2;\ndecay = 1;\nt = $bm_sub(time, inPoint);\nx = $bm_sum($bm_transform.scale[0], $bm_div($bm_mul(maxDev, Math.sin($bm_mul(spd, t))), Math.exp($bm_mul(decay, t))));\ny = $bm_div($bm_mul($bm_transform.scale[0], $bm_transform.scale[1]), x);\n$bm_rt = [\n    x,\n    y\n];',
        },
        a: { a: 0, k: [139.5, 58, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.589, -1.255],
                    [0, 0],
                    [-4.151, 3.227],
                    [-0.767, 0.065],
                    [-0.095, -0.479],
                  ],
                  o: [
                    [-0.461, 0.092],
                    [-4.362, 3.443],
                    [2.49, -2.488],
                    [0.675, -0.526],
                    [0, 0],
                    [0.096, 0.479],
                  ],
                  v: [
                    [6.991, -5.647],
                    [2.969, -3.35],
                    [-7.949, 6.796],
                    [4.118, -5.073],
                    [6.799, -6.605],
                    [7.853, -6.317],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [208.429, 80.842], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.156, 2.364],
                    [-2.434, 2.22],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.433, 2.221],
                    [-2.158, -2.364],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [8.469, 0.342],
                    [2.001, 6.242],
                    [-6.311, 5.98],
                    [-5.809, -2.32],
                    [0.924, -8.463],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.898000021542, 0.913999968884, 0.941000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [205.137, 89.82], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.157, 2.364],
                    [-2.434, 2.221],
                    [0, 0],
                    [-0.177, 0.161],
                    [-2.165, -2.374],
                    [2.139, -2.252],
                  ],
                  o: [
                    [0, 0],
                    [-2.433, 2.219],
                    [-2.156, -2.364],
                    [0, 0],
                    [0.225, -0.202],
                    [2.443, -2.228],
                    [2.066, 2.265],
                    [0, 0],
                  ],
                  v: [
                    [10.542, -1.664],
                    [-2.644, 10.364],
                    [-10.956, 10.103],
                    [-10.454, 1.801],
                    [2.152, -9.731],
                    [2.839, -10.355],
                    [11.046, -9.995],
                    [10.855, -1.979],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [209.87, 85.62], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Capa 1/@1xilustración 1 contornos',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [100, 125, 0], ix: 2 },
        a: { a: 0, k: [111.5, 96, 0], ix: 1 },
        s: { a: 0, k: [89, 89, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.921, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, -0.919],
                    [0, 0],
                  ],
                  v: [
                    [7.173, -7.721],
                    [-7.173, 7.722],
                    [-7.173, -6.056],
                    [-5.505, -7.721],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [145.939, 162.838], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-34.935, 1.417],
                    [34.934, 1.417],
                    [34.934, -1.417],
                    [-34.935, -1.417],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [103.758, 100.825], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-34.935, 1.417],
                    [34.934, 1.417],
                    [34.934, -1.418],
                    [-34.935, -1.418],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [103.758, 88.643], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-34.935, 1.418],
                    [34.934, 1.418],
                    [34.934, -1.418],
                    [-34.935, -1.418],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [103.758, 75.612], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-34.935, 1.419],
                    [34.934, 1.419],
                    [34.934, -1.419],
                    [-34.935, -1.419],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.616000007181, 0.834999952129, 0.929000016755, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [103.758, 63.434], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.133, 0],
                    [0, 0],
                    [0, -1.133],
                    [-1.133, 0],
                    [0, 0],
                    [0, -0.964],
                    [-2.684, 0],
                    [0, 0],
                    [0, 2.682],
                    [0.486, 0.753],
                    [0, 0],
                    [0, 1.133],
                  ],
                  o: [
                    [0, 0],
                    [-1.133, 0],
                    [0, 1.133],
                    [0, 0],
                    [-0.486, 0.753],
                    [0, 2.682],
                    [0, 0],
                    [2.678, 0],
                    [0, -0.964],
                    [0, 0],
                    [1.133, 0],
                    [0, -1.133],
                  ],
                  v: [
                    [23.166, -5.791],
                    [-23.165, -5.791],
                    [-25.217, -3.736],
                    [-23.165, -1.68],
                    [-19.587, -1.68],
                    [-20.365, 0.932],
                    [-15.505, 5.791],
                    [15.508, 5.791],
                    [20.368, 0.932],
                    [19.586, -1.68],
                    [23.166, -1.68],
                    [25.217, -3.736],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.898000021542, 0.913999968884, 0.941000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [102.216, 23.338], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.143, 0],
                    [0, 0],
                    [0, 0.923],
                    [0, 0],
                    [-0.921, 0],
                    [0, 0],
                    [0, -0.92],
                  ],
                  o: [
                    [-0.035, 0.036],
                    [0, 0],
                    [-0.921, 0],
                    [0, 0],
                    [0, -0.92],
                    [0, 0],
                    [0.917, 0],
                    [0, 0],
                  ],
                  v: [
                    [49.317, 48.095],
                    [35.064, 63.445],
                    [-47.656, 63.445],
                    [-49.317, 61.785],
                    [-49.317, -61.785],
                    [-47.656, -63.445],
                    [47.66, -63.445],
                    [49.317, -61.785],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [103.758, 107.07], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.866, 0],
                    [0, 0],
                    [0, -3.866],
                    [0, 0],
                    [3.866, 0],
                    [0, 0],
                    [0, 3.866],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [3.866, 0],
                    [0, 0],
                    [0, 3.866],
                    [0, 0],
                    [-3.866, 0],
                    [0, 0],
                    [0, -3.866],
                  ],
                  v: [
                    [-53.5, -85],
                    [53.5, -85],
                    [60.5, -78],
                    [60.5, 78],
                    [53.5, 85],
                    [-53.5, 85],
                    [-60.5, 78],
                    [-60.5, -78],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [102.5, 106.547], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 750,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

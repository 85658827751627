import React from 'react';
import Deck from './container/Deck';
import Header from './container/Header';
import MailTo from './container/MailTo';
import Modules from './container/Modules';

const App: React.FC = () => {
  return (
    <>
      <Header />
      <Deck />
      <Modules />
      <MailTo />
    </>
  );
};

export default App;

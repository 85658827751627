/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Lottie from 'react-lottie-player';
import header from '../animations/header';

interface IHeader {}

const Header: React.FC<IHeader> = () => {
  return (
    <section className="bg-light-cyan">
      <div className="container mx-auto flex flex-col justify-center items-center px-4 h-screen">
        <div className="flex flex-col xl:flex-row justify-center xl:justify-start items-center ">
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-row justify-center xl:justify-start items-center">
              <img
                src={'assets/images/logo.svg'}
                className="w-16 md:w-20 lg:w-40"
              />
              <span className="text-6xl md:text-7xl lg:text-9xl color-blue font-semibold">
                Certif
              </span>
              <span className="text-6xl md:text-7xl lg:text-9xl color-cyan font-semibold">
                Aid
              </span>
            </div>
            <h2 className="text-xl lg:text-5xl color-blue font-normal text-left">
              Own your health
            </h2>
          </div>
          <Lottie
            animationData={header}
            play
            loop
            className="xl:ml-10 w-full md:w-1/2"
          />
        </div>
        <h3 className="text-lg lg:text-3xl color-blue font-normal text-center px-10">
          Gestisci i tuoi dati sanitari, crea certificazione in base al tuo profilo clinico e monitora il tuo stato di salute
        </h3>
      </div>
    </section>
  );
};

export default Header;

export default {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE ', a: '', k: '', d: '', tc: '' },
  fr: 60,
  ip: 0,
  op: 321,
  w: 1018,
  h: 1018,
  nm: '68 - Certificate',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'Trigger',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [509, 509, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 3,
      nm: 'Out Animation',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 250,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [1], y: [0] },
              t: 270,
              s: [5],
            },
            { t: 310, s: [-25] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 250,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [1, 1, 0.333], y: [0, 0, 0] },
              t: 270,
              s: [110, 110, 100],
            },
            { t: 310, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 2 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.355], y: [1] },
              o: { x: [0.643], y: [0] },
              t: 85,
              s: [-180],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.645], y: [0] },
              t: 125,
              s: [3],
            },
            { t: 145, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [237.986, 296.448, 0], ix: 2 },
        a: { a: 0, k: [687.986, 746.448, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 85,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 125,
              s: [101.474, 101.474, 100],
            },
            { t: 145, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.392, 0],
                    [0, 0],
                    [0, 2.392],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [2.393, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.392],
                  ],
                  v: [
                    [0, 10.552],
                    [0, 10.552],
                    [4.331, 6.221],
                    [4.331, -10.552],
                    [-4.331, -10.552],
                    [-4.331, 6.221],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.964999988032, 0.677999997606, 0.039000002543, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [672.15, 789.072], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.392, 0],
                    [0, 0],
                    [0, 2.392],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [2.393, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.392],
                  ],
                  v: [
                    [0, 10.552],
                    [0, 10.552],
                    [4.331, 6.221],
                    [4.331, -10.552],
                    [-4.331, -10.552],
                    [-4.331, 6.221],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.964999988032, 0.677999997606, 0.039000002543, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [689.109, 789.072], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.393, 0],
                    [0, 0],
                    [0, -2.393],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.392, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.393],
                  ],
                  v: [
                    [0, -10.552],
                    [0, -10.552],
                    [-4.331, -6.221],
                    [-4.331, 10.552],
                    [4.331, 10.552],
                    [4.331, -6.221],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.964999988032, 0.677999997606, 0.039000002543, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [689.109, 703.824], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.393, 0],
                    [0, 0],
                    [0, -2.393],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.392, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.393],
                  ],
                  v: [
                    [0, -10.552],
                    [0, -10.552],
                    [-4.331, -6.221],
                    [-4.331, 10.552],
                    [4.331, 10.552],
                    [4.331, -6.221],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.964999988032, 0.677999997606, 0.039000002543, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [672.15, 703.824], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 5.658],
                    [7.915, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, -5.562],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [7.915, 0],
                  ],
                  v: [
                    [14.898, 13.388],
                    [2.737, 5.186],
                    [-15.081, 5.186],
                    [-15.081, 21.495],
                    [2.737, 21.495],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 5.185],
                    [7.447, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [7.447, 0],
                    [0, -5.186],
                    [0, 0],
                  ],
                  v: [
                    [-15.081, -21.495],
                    [-15.081, -5.938],
                    [0.096, -5.938],
                    [11.596, -13.765],
                    [0.096, -21.495],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -8.298],
                    [17.35, 0],
                    [0, 0],
                    [0, 1.903],
                    [0, 0],
                    [-1.903, 0],
                    [0, 0],
                    [0, -10.279],
                    [5.374, -2.829],
                  ],
                  o: [
                    [0, 11.409],
                    [0, 0],
                    [-1.903, 0],
                    [0, 0],
                    [0, -1.904],
                    [0, 0],
                    [16.498, 0],
                    [0, 6.599],
                    [7.355, 2.356],
                  ],
                  v: [
                    [30.266, 14.992],
                    [3.865, 33],
                    [-26.82, 33],
                    [-30.266, 29.553],
                    [-30.266, -29.553],
                    [-26.82, -33],
                    [1.984, -33],
                    [26.966, -15.839],
                    [18.198, -1.32],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.964999988032, 0.677999997606, 0.039000002543, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [687.986, 747.375], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 3 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [508.505, 508.505, 0], ix: 2 },
        a: { a: 0, k: [508.505, 508.505, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -40.23],
                    [40.231, 0],
                    [0, 40.23],
                    [-40.23, 0],
                  ],
                  o: [
                    [0, 40.23],
                    [-40.23, 0],
                    [0, -40.23],
                    [40.231, 0],
                  ],
                  v: [
                    [72.844, 0],
                    [-0.001, 72.844],
                    [-72.844, 0],
                    [-0.001, -72.844],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.830999995213, 0.19199999641, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [683.033, 746.448], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -43.127],
                    [43.127, 0],
                    [0, 43.127],
                    [-43.127, 0],
                  ],
                  o: [
                    [0, 43.127],
                    [-43.127, 0],
                    [0, -43.127],
                    [43.127, 0],
                  ],
                  v: [
                    [78.089, 0],
                    [-0.001, 78.088],
                    [-78.089, 0],
                    [-0.001, -78.089],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.948999980852, 0.757000014361, 0.144999994016, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [683.033, 746.448], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 4 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [233.033, 296.448, 0], ix: 2 },
        a: { a: 0, k: [683.033, 746.448, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 75,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 115,
              s: [103, 103, 100],
            },
            { t: 135, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -51.292],
                    [51.292, 0],
                    [0, 51.292],
                    [-51.292, 0],
                  ],
                  o: [
                    [0, 51.292],
                    [-51.292, 0],
                    [0, -51.292],
                    [51.292, 0],
                  ],
                  v: [
                    [92.872, 0.001],
                    [-0.001, 92.872],
                    [-92.872, 0.001],
                    [-0.001, -92.872],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.685999971278, 0.063000002094, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [683.033, 746.448], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 5 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.355], y: [1] },
              o: { x: [0.643], y: [0] },
              t: 95,
              s: [-34],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.331], y: [0] },
              t: 135,
              s: [3],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 150.832,
              s: [-3],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 166.668,
              s: [2],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 182.5,
              s: [-2],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 198.332,
              s: [1],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 214.168,
              s: [-1],
            },
            { t: 230, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [238.525, 303.263, 0], ix: 2 },
        a: { a: 0, k: [688.525, 753.263, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 95,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.331, 0.331, 0.333], y: [0, 0, 0] },
              t: 135,
              s: [103, 103, 100],
            },
            { t: 150.83203125, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-15.541, 22.488],
                    [15.541, -22.489],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.757000014361, 0.030999998953, 0.030999998953, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [618.371, 855.218], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-27.034, 102.865],
                    [-29.29, 42.601],
                    [-86.365, 62.074],
                    [27.034, -102.865],
                    [86.365, -62.074],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.877999997606, 0.090000002992, 0.090000002992, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [631.91, 835.128], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Layer 6 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.355], y: [1] },
              o: { x: [0.643], y: [0] },
              t: 95,
              s: [32],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.331], y: [0] },
              t: 135,
              s: [-3],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 150.834,
              s: [3],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 166.666,
              s: [-2],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 182.5,
              s: [2],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 198.334,
              s: [-1],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 214.166,
              s: [1],
            },
            { t: 230, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [227.024, 303.013, 0], ix: 2 },
        a: { a: 0, k: [677.024, 753.013, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 95,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.331, 0.331, 0.333], y: [0, 0, 0] },
              t: 135,
              s: [103, 103, 100],
            },
            { t: 150.833984375, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [15.67, 22.4],
                    [-15.669, -22.399],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.757000014361, 0.030999998953, 0.030999998953, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [748.398, 854.983], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [27.624, 102.709],
                    [29.534, 42.434],
                    [86.719, 61.579],
                    [-27.623, -102.708],
                    [-86.719, -61.578],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.877999997606, 0.090000002992, 0.090000002992, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [734.744, 834.971], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 7 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-92.085, 351.624, 0], ix: 2 },
        a: { a: 0, k: [357.915, 801.624, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 65,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 105,
              s: [103, 100, 100],
            },
            { t: 125, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.248, 0],
                    [0, 0],
                    [0, 3.249],
                    [-3.248, 0],
                    [0, 0],
                    [0, -3.248],
                  ],
                  o: [
                    [0, 0],
                    [-3.248, 0],
                    [0, -3.248],
                    [0, 0],
                    [3.248, 0],
                    [0, 3.249],
                  ],
                  v: [
                    [176.942, 5.883],
                    [-176.941, 5.883],
                    [-182.823, -0.001],
                    [-176.941, -5.883],
                    [176.942, -5.883],
                    [182.823, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [540.738, 801.624], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Layer 8 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-92.085, 292.33, 0], ix: 2 },
        a: { a: 0, k: [357.915, 742.33, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 60,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 100,
              s: [103, 100, 100],
            },
            { t: 120, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.248, 0],
                    [0, 0],
                    [0, 3.249],
                    [-3.248, 0],
                    [0, 0],
                    [0, -3.248],
                  ],
                  o: [
                    [0, 0],
                    [-3.248, 0],
                    [0, -3.248],
                    [0, 0],
                    [3.248, 0],
                    [0, 3.249],
                  ],
                  v: [
                    [176.942, 5.882],
                    [-176.941, 5.882],
                    [-182.823, 0],
                    [-176.941, -5.883],
                    [176.942, -5.883],
                    [182.823, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [540.738, 742.33], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Layer 9 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-92.085, 233.036, 0], ix: 2 },
        a: { a: 0, k: [357.915, 683.036, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 55,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 95,
              s: [103, 100, 100],
            },
            { t: 115, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.248, 0],
                    [0, 0],
                    [0, 3.249],
                    [-3.248, 0],
                    [0, 0],
                    [0, -3.249],
                  ],
                  o: [
                    [0, 0],
                    [-3.248, 0],
                    [0, -3.249],
                    [0, 0],
                    [3.248, 0],
                    [0, 3.249],
                  ],
                  v: [
                    [176.942, 5.883],
                    [-176.941, 5.883],
                    [-182.823, -0.001],
                    [-176.941, -5.883],
                    [176.942, -5.883],
                    [182.823, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [540.738, 683.036], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Layer 10 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-92.085, 173.742, 0], ix: 2 },
        a: { a: 0, k: [357.915, 623.742, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 50,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 90,
              s: [103, 100, 100],
            },
            { t: 110, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.248, 0],
                    [0, 0],
                    [0, 3.249],
                    [-3.248, 0],
                    [0, 0],
                    [0, -3.249],
                  ],
                  o: [
                    [0, 0],
                    [-3.248, 0],
                    [0, -3.249],
                    [0, 0],
                    [3.248, 0],
                    [0, 3.249],
                  ],
                  v: [
                    [176.942, 5.883],
                    [-176.941, 5.883],
                    [-182.823, 0.001],
                    [-176.941, -5.883],
                    [176.942, -5.883],
                    [182.823, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [540.738, 623.742], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Layer 11 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-92.085, 114.448, 0], ix: 2 },
        a: { a: 0, k: [357.915, 564.448, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 45,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 85,
              s: [103, 100, 100],
            },
            { t: 105, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.248, 0],
                    [0, 0],
                    [0, 3.248],
                    [-3.248, 0],
                    [0, 0],
                    [0, -3.249],
                  ],
                  o: [
                    [0, 0],
                    [-3.248, 0],
                    [0, -3.249],
                    [0, 0],
                    [3.248, 0],
                    [0, 3.248],
                  ],
                  v: [
                    [176.942, 5.883],
                    [-176.941, 5.883],
                    [-182.823, 0],
                    [-176.941, -5.882],
                    [176.942, -5.882],
                    [182.823, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [540.738, 564.448], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Layer 12 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-92.085, 55.154, 0], ix: 2 },
        a: { a: 0, k: [357.915, 505.154, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 40,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 80,
              s: [103, 100, 100],
            },
            { t: 100, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.248, 0],
                    [0, 0],
                    [0, 3.249],
                    [-3.248, 0],
                    [0, 0],
                    [0, -3.249],
                  ],
                  o: [
                    [0, 0],
                    [-3.248, 0],
                    [0, -3.249],
                    [0, 0],
                    [3.248, 0],
                    [0, 3.249],
                  ],
                  v: [
                    [176.942, 5.882],
                    [-176.941, 5.882],
                    [-182.823, 0],
                    [-176.941, -5.883],
                    [176.942, -5.883],
                    [182.823, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [540.738, 505.154], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Layer 13 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-92.085, -4.14, 0], ix: 2 },
        a: { a: 0, k: [357.915, 445.86, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 35,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 75,
              s: [103, 100, 100],
            },
            { t: 95, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.248, 0],
                    [0, 0],
                    [0, 3.248],
                    [-3.248, 0],
                    [0, 0],
                    [0, -3.249],
                  ],
                  o: [
                    [0, 0],
                    [-3.248, 0],
                    [0, -3.249],
                    [0, 0],
                    [3.248, 0],
                    [0, 3.248],
                  ],
                  v: [
                    [176.942, 5.882],
                    [-176.941, 5.882],
                    [-182.823, 0.001],
                    [-176.941, -5.882],
                    [176.942, -5.882],
                    [182.823, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [540.738, 445.86], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Layer 14 Outlines',
      parent: 17,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [357.915, 386.566, 0], ix: 2 },
        a: { a: 0, k: [357.915, 386.566, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 70,
              s: [103, 100, 100],
            },
            { t: 90, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.248, 0],
                    [0, 0],
                    [0, 3.249],
                    [-3.248, 0],
                    [0, 0],
                    [0, -3.248],
                  ],
                  o: [
                    [0, 0],
                    [-3.248, 0],
                    [0, -3.248],
                    [0, 0],
                    [3.248, 0],
                    [0, 3.249],
                  ],
                  v: [
                    [176.942, 5.882],
                    [-176.941, 5.882],
                    [-182.823, -0.001],
                    [-176.941, -5.882],
                    [176.942, -5.882],
                    [182.823, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [540.738, 386.566], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Layer 15 Outlines',
      parent: 17,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [536.21, 311.431, 0], ix: 2 },
        a: { a: 0, k: [536.21, 311.431, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 10,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 50,
              s: [103, 103, 100],
            },
            { t: 70, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.816, 0],
                    [0.971, -1.279],
                    [0.227, -2.084],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.898, 1.146],
                  ],
                  o: [
                    [-1.671, 0],
                    [-0.97, 1.28],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -1.919],
                    [-0.897, -1.145],
                  ],
                  v: [
                    [0.417, -10.407],
                    [-3.545, -8.488],
                    [-5.34, -3.441],
                    [-5.247, -3.286],
                    [5.835, -3.286],
                    [5.835, -4.092],
                    [4.488, -8.689],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.704, 0],
                    [2.888, 3.095],
                    [0, 4.767],
                    [0, 0],
                    [-2.735, 3.22],
                    [-4.602, -0.02],
                    [-2.497, -2.724],
                    [0, -4.643],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.312, -1.418],
                    [-2.25, 0],
                    [-1.32, 0.403],
                    [-1.569, 0.866],
                    [0, 0],
                    [2.207, -0.735],
                  ],
                  o: [
                    [-4.829, 0],
                    [-2.89, -3.096],
                    [0, 0],
                    [0, -4.974],
                    [2.734, -3.219],
                    [4.521, 0],
                    [2.497, 2.724],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.165, 2.2],
                    [1.309, 1.418],
                    [2.001, 0],
                    [1.321, -0.402],
                    [0, 0],
                    [-1.362, 1.12],
                    [-2.209, 0.736],
                  ],
                  v: [
                    [1.223, 17.392],
                    [-10.354, 12.749],
                    [-14.688, 0.954],
                    [-14.688, -0.283],
                    [-10.587, -12.574],
                    [0.417, -17.371],
                    [10.942, -13.285],
                    [14.688, -2.234],
                    [14.688, 2.688],
                    [-5.309, 2.688],
                    [-5.371, 2.873],
                    [-3.157, 8.3],
                    [2.183, 10.426],
                    [7.166, 9.822],
                    [11.5, 7.92],
                    [13.945, 13.504],
                    [8.591, 16.288],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [668.344, 318.834], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.536, -0.557],
                    [-0.909, 0],
                    [-0.34, 0.052],
                    [-0.454, 0.124],
                    [0, 0],
                    [0.867, -0.155],
                    [1.011, 0],
                    [1.682, 1.713],
                    [0, 3.715],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 1.3],
                    [0.536, 0.558],
                    [0.474, 0],
                    [0.341, -0.051],
                    [0, 0],
                    [-0.908, 0.31],
                    [-0.867, 0.155],
                    [-3.116, 0],
                    [-1.682, -1.713],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.885, -21.174],
                    [3.885, -12.971],
                    [9.519, -12.971],
                    [9.519, -6.625],
                    [3.885, -6.625],
                    [3.885, 10.432],
                    [4.69, 13.218],
                    [6.857, 14.055],
                    [8.079, 13.977],
                    [9.272, 13.714],
                    [10.045, 20.245],
                    [7.383, 20.942],
                    [4.566, 21.174],
                    [-2.631, 18.604],
                    [-5.154, 10.463],
                    [-5.154, -6.625],
                    [-10.045, -6.625],
                    [-10.045, -12.971],
                    [-5.154, -12.971],
                    [-5.154, -21.174],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [639.462, 315.052], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.28, 0],
                    [-1.237, 0.784],
                    [-0.435, 1.032],
                    [0, 0],
                    [0, 0],
                    [0.99, -0.96],
                    [0, -1.362],
                    [-0.742, -0.682],
                  ],
                  o: [
                    [1.588, 0],
                    [1.24, -0.784],
                    [0, 0],
                    [0, 0],
                    [-1.92, 0],
                    [-0.99, 0.959],
                    [0, 1.115],
                    [0.743, 0.68],
                  ],
                  v: [
                    [-2.073, 10.695],
                    [2.166, 9.519],
                    [4.675, 6.795],
                    [4.675, 2.058],
                    [-0.372, 2.058],
                    [-4.736, 3.498],
                    [-6.221, 6.98],
                    [-5.108, 9.674],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.236, 0.846],
                    [0.103, 0.888],
                    [1.517, -1.073],
                    [2.105, 0],
                    [1.899, 1.806],
                    [0, 3.116],
                    [-2.529, 1.806],
                    [-4.849, 0],
                    [0, 0],
                    [0, 0],
                    [0.845, 0.887],
                    [1.651, 0],
                    [0.794, -0.712],
                    [0, -1.259],
                    [0, 0],
                    [0, 0],
                    [-2.6, 2.054],
                    [-4.23, 0],
                    [-2.497, -2.032],
                    [0, -3.756],
                    [0, 0],
                    [-0.237, -1.382],
                    [-0.515, -1.362],
                  ],
                  o: [
                    [-0.33, -0.763],
                    [-0.237, -0.846],
                    [-0.928, 1.631],
                    [-1.516, 1.072],
                    [-3.508, 0],
                    [-1.898, -1.806],
                    [0, -3.282],
                    [2.527, -1.805],
                    [0, 0],
                    [0, 0],
                    [0, -1.609],
                    [-0.847, -0.887],
                    [-1.464, 0],
                    [-0.794, 0.712],
                    [0, 0],
                    [0, 0],
                    [-0.146, -2.827],
                    [2.601, -2.053],
                    [4.003, 0],
                    [2.498, 2.033],
                    [0, 0],
                    [0, 1.569],
                    [0.238, 1.383],
                    [0, 0],
                  ],
                  v: [
                    [6.192, 16.731],
                    [5.34, 14.317],
                    [4.829, 11.716],
                    [1.16, 15.772],
                    [-4.272, 17.382],
                    [-12.383, 14.673],
                    [-15.23, 7.29],
                    [-11.438, -0.341],
                    [-0.372, -3.05],
                    [4.675, -3.05],
                    [4.675, -5.712],
                    [3.406, -9.457],
                    [-0.341, -10.788],
                    [-3.73, -9.72],
                    [-4.922, -6.764],
                    [-13.652, -6.795],
                    [-13.682, -6.981],
                    [-9.999, -14.302],
                    [0.248, -17.382],
                    [9.998, -14.333],
                    [13.744, -5.65],
                    [13.744, 8.187],
                    [14.1, 12.614],
                    [15.23, 16.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [612.021, 318.844], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.414, 0],
                    [-0.971, 0.934],
                    [0, 1.56],
                    [0, 0],
                    [0, 0],
                    [2.631, -2.281],
                    [3.901, 0],
                    [2.703, 3.157],
                    [0, 4.953],
                    [0, 0],
                    [-2.692, 3.178],
                    [-4.994, 0],
                    [-2.527, -2.353],
                    [0.082, -3.839],
                    [0, 0],
                    [0, 0],
                    [0.949, 1.146],
                    [1.671, 0],
                    [0.981, -1.854],
                    [0, -2.926],
                    [0, 0],
                    [-0.98, -1.824],
                  ],
                  o: [
                    [1.61, 0],
                    [0.97, -0.933],
                    [0, 0],
                    [0, 0],
                    [0.082, 3.385],
                    [-2.631, 2.28],
                    [-4.994, 0],
                    [-2.704, -3.158],
                    [0, 0],
                    [0, -4.932],
                    [2.694, -3.177],
                    [4.127, 0],
                    [2.528, 2.352],
                    [0, 0],
                    [0, 0],
                    [0, -1.734],
                    [-0.95, -1.145],
                    [-2.395, 0],
                    [-0.98, 1.855],
                    [0, 0],
                    [0, 2.989],
                    [0.981, 1.823],
                  ],
                  v: [
                    [0.95, 10.416],
                    [4.82, 9.017],
                    [6.274, 5.277],
                    [14.477, 5.277],
                    [14.57, 5.464],
                    [10.747, 13.962],
                    [0.95, 17.382],
                    [-10.596, 12.645],
                    [-14.653, 0.48],
                    [-14.653, -0.449],
                    [-10.612, -12.614],
                    [0.919, -17.382],
                    [10.901, -13.853],
                    [14.57, -4.566],
                    [14.51, -4.379],
                    [6.274, -4.379],
                    [4.85, -8.699],
                    [0.919, -10.416],
                    [-4.143, -7.634],
                    [-5.612, -0.463],
                    [-5.612, 0.464],
                    [-4.143, 7.681],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [578.846, 318.844], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.52, 24.145],
                    [-4.519, 24.145],
                    [-4.519, -9.349],
                    [4.52, -9.349],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.52, -17.366],
                    [-4.519, -17.366],
                    [-4.519, -24.145],
                    [4.52, -24.145],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [553.793, 311.43], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.167, 2.034],
                    [-3.921, 0],
                    [-0.795, -0.112],
                    [-0.949, -0.206],
                    [0, 0],
                    [0.465, 0.062],
                    [0.619, 0],
                    [0.753, -0.805],
                    [0, -1.507],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -3.756],
                    [2.167, -2.032],
                    [0.764, 0],
                    [0.795, 0.114],
                    [0, 0],
                    [-0.496, -0.083],
                    [-0.465, -0.062],
                    [-1.466, 0],
                    [-0.754, 0.804],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.176, 24.47],
                    [-6.176, -2.678],
                    [-11.159, -2.678],
                    [-11.159, -9.024],
                    [-6.176, -9.024],
                    [-6.176, -12.738],
                    [-2.926, -21.422],
                    [6.206, -24.47],
                    [8.543, -24.301],
                    [11.159, -23.821],
                    [10.386, -17.103],
                    [8.946, -17.32],
                    [7.321, -17.413],
                    [3.993, -16.205],
                    [2.863, -12.738],
                    [2.863, -9.024],
                    [9.518, -9.024],
                    [9.518, -2.678],
                    [2.863, -2.678],
                    [2.863, 24.47],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [534.678, 311.105], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.52, 24.145],
                    [-4.519, 24.145],
                    [-4.519, -9.349],
                    [4.52, -9.349],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.52, -17.366],
                    [-4.519, -17.366],
                    [-4.519, -24.145],
                    [4.52, -24.145],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [514.2, 311.43], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.536, -0.557],
                    [-0.908, 0],
                    [-0.341, 0.052],
                    [-0.454, 0.124],
                    [0, 0],
                    [0.866, -0.155],
                    [1.011, 0],
                    [1.682, 1.713],
                    [0, 3.715],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 1.3],
                    [0.536, 0.558],
                    [0.474, 0],
                    [0.341, -0.051],
                    [0, 0],
                    [-0.908, 0.31],
                    [-0.868, 0.155],
                    [-3.117, 0],
                    [-1.682, -1.713],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.885, -21.174],
                    [3.885, -12.971],
                    [9.518, -12.971],
                    [9.518, -6.625],
                    [3.885, -6.625],
                    [3.885, 10.432],
                    [4.69, 13.218],
                    [6.857, 14.055],
                    [8.079, 13.977],
                    [9.272, 13.714],
                    [10.044, 20.245],
                    [7.384, 20.942],
                    [4.566, 21.174],
                    [-2.632, 18.604],
                    [-5.154, 10.463],
                    [-5.154, -6.625],
                    [-10.044, -6.625],
                    [-10.044, -12.971],
                    [-5.154, -12.971],
                    [-5.154, -21.174],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [493.94, 315.052], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0.867, -0.557],
                    [0.454, -1.011],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.249, 0.981],
                    [-1.631, 0],
                    [-0.382, -0.072],
                    [-0.393, -0.103],
                  ],
                  o: [
                    [0, 0],
                    [-1.341, 0],
                    [-0.867, 0.557],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.784, -1.754],
                    [1.248, -0.98],
                    [0.454, 0],
                    [0.382, 0.073],
                    [0, 0],
                  ],
                  v: [
                    [8.328, -8.668],
                    [5.014, -8.76],
                    [1.703, -7.924],
                    [-0.279, -5.573],
                    [-0.279, 17.057],
                    [-9.286, 17.057],
                    [-9.286, -16.439],
                    [-0.897, -16.439],
                    [-0.496, -11.484],
                    [2.555, -15.586],
                    [6.872, -17.057],
                    [8.125, -16.948],
                    [9.286, -16.685],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [473.339, 318.519], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.815, 0],
                    [0.969, -1.279],
                    [0.227, -2.084],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.898, 1.146],
                  ],
                  o: [
                    [-1.672, 0],
                    [-0.971, 1.28],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -1.919],
                    [-0.897, -1.145],
                  ],
                  v: [
                    [0.418, -10.407],
                    [-3.544, -8.488],
                    [-5.34, -3.441],
                    [-5.247, -3.286],
                    [5.835, -3.286],
                    [5.835, -4.092],
                    [4.488, -8.689],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.703, 0],
                    [2.888, 3.095],
                    [0, 4.767],
                    [0, 0],
                    [-2.735, 3.22],
                    [-4.603, -0.02],
                    [-2.497, -2.724],
                    [0, -4.643],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.31, -1.418],
                    [-2.249, 0],
                    [-1.321, 0.403],
                    [-1.569, 0.866],
                    [0, 0],
                    [2.208, -0.735],
                  ],
                  o: [
                    [-4.829, 0],
                    [-2.89, -3.096],
                    [0, 0],
                    [0, -4.974],
                    [2.734, -3.219],
                    [4.519, 0],
                    [2.496, 2.724],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.165, 2.2],
                    [1.311, 1.418],
                    [2.002, 0],
                    [1.32, -0.402],
                    [0, 0],
                    [-1.361, 1.12],
                    [-2.208, 0.736],
                  ],
                  v: [
                    [1.223, 17.392],
                    [-10.354, 12.749],
                    [-14.688, 0.954],
                    [-14.688, -0.283],
                    [-10.587, -12.574],
                    [0.418, -17.371],
                    [10.943, -13.285],
                    [14.688, -2.234],
                    [14.688, 2.688],
                    [-5.309, 2.688],
                    [-5.371, 2.873],
                    [-3.158, 8.3],
                    [2.182, 10.426],
                    [7.166, 9.822],
                    [11.5, 7.92],
                    [13.945, 13.504],
                    [8.59, 16.288],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [444.102, 318.834], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [3.044, -2.847],
                    [5.407, 0],
                    [3.384, 3.489],
                    [0, 5.49],
                    [0, 0],
                    [-3.303, 3.508],
                    [-5.283, 0],
                    [-3.137, -2.858],
                    [0.082, -4.932],
                    [0, 0],
                    [0, 0],
                    [1.424, 1.579],
                    [3.013, 0],
                    [1.589, -2.196],
                    [0, -3.402],
                    [0, 0],
                    [-1.672, -2.196],
                    [-2.848, 0],
                    [-1.352, 1.517],
                    [0, 2.993],
                  ],
                  o: [
                    [0, 0],
                    [0.082, 4.85],
                    [-3.044, 2.848],
                    [-5.428, 0],
                    [-3.386, -3.486],
                    [0, 0],
                    [0, -5.468],
                    [3.301, -3.509],
                    [5.572, 0],
                    [3.137, 2.859],
                    [0, 0],
                    [0, 0],
                    [0, -2.972],
                    [-1.424, -1.579],
                    [-2.704, 0],
                    [-1.589, 2.195],
                    [0, 0],
                    [0, 3.443],
                    [1.672, 2.196],
                    [2.828, 0],
                    [1.351, -1.516],
                    [0, 0],
                  ],
                  v: [
                    [17.573, 7.181],
                    [17.635, 7.367],
                    [13.193, 18.914],
                    [0.516, 23.185],
                    [-12.701, 17.953],
                    [-17.779, 4.488],
                    [-17.779, -4.458],
                    [-12.825, -17.922],
                    [0.052, -23.185],
                    [13.115, -18.898],
                    [17.697, -7.213],
                    [17.635, -7.026],
                    [8.844, -7.026],
                    [6.707, -13.853],
                    [0.052, -16.221],
                    [-6.387, -12.927],
                    [-8.77, -4.531],
                    [-8.77, 4.469],
                    [-6.263, 12.927],
                    [0.516, 16.22],
                    [6.785, 13.945],
                    [8.813, 7.181],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.078000005086, 0.078000005086, 0.078000005086, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [407.167, 313.04], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Layer 16 Outlines',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.355], y: [1] },
              o: { x: [0.643], y: [0] },
              t: 0,
              s: [-20],
            },
            {
              i: { x: [0.356], y: [1] },
              o: { x: [0.645], y: [0] },
              t: 40,
              s: [3],
            },
            { t: 60, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [83.093, 106.753, 0], ix: 2 },
        a: { a: 0, k: [533.093, 556.753, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.643, 0.643, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.356, 0.356, 0.667], y: [1, 1, 1] },
              o: { x: [0.645, 0.645, 0.333], y: [0, 0, 0] },
              t: 40,
              s: [103, 103, 100],
            },
            { t: 60, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.625, 0],
                    [0, 0],
                    [0, 8.625],
                    [0, 0],
                    [-8.625, 0],
                    [0, 0],
                    [0, -8.625],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-8.625, 0],
                    [0, 0],
                    [0, -8.625],
                    [0, 0],
                    [8.625, 0],
                    [0, 0],
                    [0, 8.625],
                  ],
                  v: [
                    [215.844, 317.977],
                    [-215.844, 317.977],
                    [-231.46, 302.36],
                    [-231.46, -302.361],
                    [-215.844, -317.978],
                    [215.844, -317.978],
                    [231.46, -302.361],
                    [231.46, 302.36],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.948999980852, 0.952999997606, 0.957000014361, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [539.975, 548.282], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.625, 0],
                    [0, 0],
                    [0, 8.625],
                    [0, 0],
                    [-8.625, 0],
                    [0, 0],
                    [0, -8.625],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-8.625, 0],
                    [0, 0],
                    [0, -8.625],
                    [0, 0],
                    [8.625, 0],
                    [0, 0],
                    [0, 8.625],
                  ],
                  v: [
                    [215.844, 317.978],
                    [-215.844, 317.978],
                    [-231.46, 302.361],
                    [-231.46, -302.36],
                    [-215.844, -317.978],
                    [215.844, -317.978],
                    [231.46, -302.36],
                    [231.46, 302.361],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [526.211, 565.223], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

import React from 'react';

interface IMailTo {}

const MailTo: React.FC<IMailTo> = () => {
  return (
    <section className="py-20 px-4">
      <div className="container mx-auto flex flex-col justify-center items-center">
        <h2 className="color-blue text-4xl">Hai qualche domanda?</h2>
        <a
          href="mailto:stefano@certifaid.it"
          className="w-full lg:w-auto bg-blue color-light-cyan p-6 rounded-lg mt-10 flex flex-row justify-center items-center font-semibold text-lg lg:text-2xl"
        >
          Scrivici una mail
        </a>
      </div>
    </section>
  );
};

export default MailTo;

import React from 'react';
import Card from '../components/Card';
import thinking from '../animations/thinking';
import medicalRecords from '../animations/medical-records';
import certificate from '../animations/certificate';

interface IModules {}

const Modules: React.FC<IModules> = () => {
  const modules = [
    {
      title: 'Cartella Clinica',
      description:
        'Possiedi i tuoi dati clinici. Gestiscili in un unico posto e condividi l’accesso totale o parziale con i tuoi medici di fiducia.',
      image: medicalRecords,
    },
    {
      title: 'Certificazioni',
      description:
        'Genera i tuoi certificati autonomamente in base ai tuoi dati clinici. Valida i certificati generati e rileva eventuali anomalie.',
      image: certificate,
    },
    {
      title: 'Profilo Clinico',
      description: `Elabora il tuo profilo clinico corrente, crea versioni temporali di esso e monitora il tuo stato di salute nel tempo.`,
      image: thinking,
    },
  ];

  return (
    <section>
      <div className="container mx-auto flex flex-col justify-center items-center lg:h-screen px-4">
        <h2 className="color-blue text-4xl">Cosa facciamo?</h2>
        <div className="flex flex-row justify-center lg:justify-between items-center w-full mt-20 flex-wrap">
          {modules.map((module, index) => (
            <Card module={module} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Modules;

export default {
  v: '5.6.9',
  fr: 30,
  ip: 0,
  op: 150,
  w: 1000,
  h: 1000,
  nm: 'Online Doctor App',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Doctor text Outlines',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 27,
                  s: [100],
                },
                { t: 37.0000015070409, s: [2] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [464.126, 728.305, 0],
                  to: [0, -14, 0],
                  ti: [0, 14, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 10,
                  s: [464.126, 644.305, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 27,
                  s: [464.126, 644.305, 0],
                  to: [0, -19.167, 0],
                  ti: [0, 19.167, 0],
                },
                { t: 37.0000015070409, s: [464.126, 529.305, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [60.884, 65.911, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -21,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -14,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -11,
                  s: [95, 95, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -8,
                  s: [105, 105, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -6,
                  s: [98, 98, 100],
                },
                { t: -4.00000016292334, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [5.83, 0],
                        [0, -5.829],
                      ],
                      o: [
                        [0, -5.829],
                        [-5.83, 0],
                        [0, 0],
                      ],
                      v: [
                        [10.556, 5.278],
                        [-0.001, -5.278],
                        [-10.556, 5.278],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [202.866, 28.768], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 51',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.206],
                        [3.206, 0],
                        [0, 3.206],
                        [-3.207, 0],
                      ],
                      o: [
                        [0, 3.206],
                        [-3.207, 0],
                        [0, -3.206],
                        [3.206, 0],
                      ],
                      v: [
                        [5.806, 0],
                        [-0.001, 5.806],
                        [-5.806, 0],
                        [-0.001, -5.806],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [202.69, 14.166], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 102',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-26.172, -1.428],
                        [26.172, -1.428],
                        [26.172, 1.428],
                        [-26.172, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [147.29, 31.18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 103',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.427],
                        [72.806, -1.427],
                        [72.806, 1.427],
                        [-72.806, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 25.787], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 104',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.428],
                        [72.806, -1.428],
                        [72.806, 1.428],
                        [-72.806, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 20.394], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 105',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.427],
                        [72.806, -1.427],
                        [72.806, 1.427],
                        [-72.806, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 15.001], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 106',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [-108.459, -30.455],
                        [108.459, -30.455],
                        [115.634, -23.28],
                        [115.634, 10.907],
                        [108.459, 18.082],
                        [-44.709, 18.082],
                        [-54.724, 30.455],
                        [-66.507, 18.082],
                        [-108.459, 18.082],
                        [-115.634, 10.907],
                        [-115.634, -23.28],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 30.705], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 107',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [-108.459, -30.455],
                        [108.459, -30.455],
                        [115.634, -23.28],
                        [115.634, 10.907],
                        [108.459, 18.082],
                        [-44.709, 18.082],
                        [-54.724, 30.455],
                        [-66.507, 18.082],
                        [-108.459, 18.082],
                        [-115.634, 10.907],
                        [-115.634, -23.28],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 37.367], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 108',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: -21.0000008553475,
          op: 130.000005295009,
          st: -21.0000008553475,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Love text Outlines',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 53,
                  s: [100],
                },
                { t: 63.0000025660426, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 27,
                  s: [580.843, 729.556, 0],
                  to: [0, -13.917, 0],
                  ti: [0, 13.917, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 37,
                  s: [580.843, 646.056, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 53,
                  s: [580.843, 646.056, 0],
                  to: [0, -21.333, 0],
                  ti: [0, 21.333, 0],
                },
                { t: 63.0000025660426, s: [580.843, 518.056, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [170.884, 67.411, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 6,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 13,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 16,
                  s: [95, 95, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 19,
                  s: [105, 105, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 21,
                  s: [98, 98, 100],
                },
                { t: 23.0000009368092, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [26.173, -1.427],
                        [-26.173, -1.427],
                        [-26.173, 1.427],
                        [26.173, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [84.477, 31.181], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.807, -1.428],
                        [-72.807, -1.428],
                        [-72.807, 1.428],
                        [72.807, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [131.112, 25.788], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.807, -1.427],
                        [-72.807, -1.427],
                        [-72.807, 1.427],
                        [72.807, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [131.112, 20.395], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.807, -1.428],
                        [-72.807, -1.428],
                        [-72.807, 1.428],
                        [72.807, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [131.112, 15.002], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.513],
                        [4.29, 0],
                        [0.236, -4.077],
                        [0, 0],
                        [0, 0],
                        [4.137, 0],
                        [0, -4.29],
                        [-0.095, -0.486],
                        [0, 0],
                        [-13.708, -5.483],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -4.29],
                        [-4.136, 0],
                        [0, 0],
                        [0, 0],
                        [-0.236, -4.077],
                        [-4.29, 0],
                        [0, 0.513],
                        [0, 0],
                        [0, 0],
                        [13.707, -5.483],
                        [0, 0],
                        [0.094, -0.486],
                      ],
                      v: [
                        [15.535, -5.026],
                        [7.767, -13.707],
                        [0.023, -6.393],
                        [0, -6.396],
                        [-0.023, -6.393],
                        [-7.768, -13.707],
                        [-15.535, -5.026],
                        [-15.388, -3.526],
                        [-15.389, -3.526],
                        [0, 13.707],
                        [15.389, -3.526],
                        [15.388, -3.526],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [30.397, 23.586], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [108.459, -30.455],
                        [-108.459, -30.455],
                        [-115.634, -23.279],
                        [-115.634, 10.908],
                        [-108.459, 18.083],
                        [44.708, 18.083],
                        [54.724, 30.455],
                        [66.508, 18.083],
                        [108.459, 18.083],
                        [115.634, 10.908],
                        [115.634, -23.279],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 30.705], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.962],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 3.962],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [108.459, -30.455],
                        [-108.459, -30.455],
                        [-115.634, -23.279],
                        [-115.634, 10.908],
                        [-108.459, 18.083],
                        [44.708, 18.083],
                        [54.724, 30.455],
                        [66.508, 18.083],
                        [108.459, 18.083],
                        [115.634, 10.908],
                        [115.634, -23.279],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 37.367], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6.00000024438501,
          op: 157.000006394741,
          st: 6.00000024438501,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Doctor text Outlines 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 80,
                  s: [100],
                },
                { t: 90.0000036657751, s: [2] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 53,
                  s: [464.126, 728.305, 0],
                  to: [0, -14, 0],
                  ti: [0, 14, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 63,
                  s: [464.126, 644.305, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 80,
                  s: [464.126, 644.305, 0],
                  to: [0, -19.167, 0],
                  ti: [0, 19.167, 0],
                },
                { t: 90.0000036657751, s: [464.126, 529.305, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [60.884, 65.911, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 32,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 39,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 42,
                  s: [95, 95, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 45,
                  s: [105, 105, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 47,
                  s: [98, 98, 100],
                },
                { t: 49.0000019958109, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [5.83, 0],
                        [0, -5.829],
                      ],
                      o: [
                        [0, -5.829],
                        [-5.83, 0],
                        [0, 0],
                      ],
                      v: [
                        [10.556, 5.278],
                        [-0.001, -5.278],
                        [-10.556, 5.278],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [202.866, 28.768], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 51',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.206],
                        [3.206, 0],
                        [0, 3.206],
                        [-3.207, 0],
                      ],
                      o: [
                        [0, 3.206],
                        [-3.207, 0],
                        [0, -3.206],
                        [3.206, 0],
                      ],
                      v: [
                        [5.806, 0],
                        [-0.001, 5.806],
                        [-5.806, 0],
                        [-0.001, -5.806],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [202.69, 14.166], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 102',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-26.172, -1.428],
                        [26.172, -1.428],
                        [26.172, 1.428],
                        [-26.172, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [147.29, 31.18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 103',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.427],
                        [72.806, -1.427],
                        [72.806, 1.427],
                        [-72.806, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 25.787], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 104',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.428],
                        [72.806, -1.428],
                        [72.806, 1.428],
                        [-72.806, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 20.394], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 105',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.427],
                        [72.806, -1.427],
                        [72.806, 1.427],
                        [-72.806, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 15.001], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 106',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [-108.459, -30.455],
                        [108.459, -30.455],
                        [115.634, -23.28],
                        [115.634, 10.907],
                        [108.459, 18.082],
                        [-44.709, 18.082],
                        [-54.724, 30.455],
                        [-66.507, 18.082],
                        [-108.459, 18.082],
                        [-115.634, 10.907],
                        [-115.634, -23.28],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 30.705], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 107',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [-108.459, -30.455],
                        [108.459, -30.455],
                        [115.634, -23.28],
                        [115.634, 10.907],
                        [108.459, 18.082],
                        [-44.709, 18.082],
                        [-54.724, 30.455],
                        [-66.507, 18.082],
                        [-108.459, 18.082],
                        [-115.634, 10.907],
                        [-115.634, -23.28],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 37.367], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 108',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 32.0000013033867,
          op: 183.000007453743,
          st: 32.0000013033867,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Love text Outlines 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 105,
                  s: [100],
                },
                { t: 115.000004684046, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 79,
                  s: [580.843, 729.556, 0],
                  to: [0, -13.917, 0],
                  ti: [0, 13.917, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 89,
                  s: [580.843, 646.056, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 105,
                  s: [580.843, 646.056, 0],
                  to: [0, -21.333, 0],
                  ti: [0, 21.333, 0],
                },
                { t: 115.000004684046, s: [580.843, 518.056, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [170.884, 67.411, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 58,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 65,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 68,
                  s: [95, 95, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 71,
                  s: [105, 105, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 73,
                  s: [98, 98, 100],
                },
                { t: 75.0000030548126, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [26.173, -1.427],
                        [-26.173, -1.427],
                        [-26.173, 1.427],
                        [26.173, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [84.477, 31.181], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.807, -1.428],
                        [-72.807, -1.428],
                        [-72.807, 1.428],
                        [72.807, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [131.112, 25.788], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.807, -1.427],
                        [-72.807, -1.427],
                        [-72.807, 1.427],
                        [72.807, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [131.112, 20.395], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.807, -1.428],
                        [-72.807, -1.428],
                        [-72.807, 1.428],
                        [72.807, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [131.112, 15.002], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.513],
                        [4.29, 0],
                        [0.236, -4.077],
                        [0, 0],
                        [0, 0],
                        [4.137, 0],
                        [0, -4.29],
                        [-0.095, -0.486],
                        [0, 0],
                        [-13.708, -5.483],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -4.29],
                        [-4.136, 0],
                        [0, 0],
                        [0, 0],
                        [-0.236, -4.077],
                        [-4.29, 0],
                        [0, 0.513],
                        [0, 0],
                        [0, 0],
                        [13.707, -5.483],
                        [0, 0],
                        [0.094, -0.486],
                      ],
                      v: [
                        [15.535, -5.026],
                        [7.767, -13.707],
                        [0.023, -6.393],
                        [0, -6.396],
                        [-0.023, -6.393],
                        [-7.768, -13.707],
                        [-15.535, -5.026],
                        [-15.388, -3.526],
                        [-15.389, -3.526],
                        [0, 13.707],
                        [15.389, -3.526],
                        [15.388, -3.526],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [30.397, 23.586], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [108.459, -30.455],
                        [-108.459, -30.455],
                        [-115.634, -23.279],
                        [-115.634, 10.908],
                        [-108.459, 18.083],
                        [44.708, 18.083],
                        [54.724, 30.455],
                        [66.508, 18.083],
                        [108.459, 18.083],
                        [115.634, 10.908],
                        [115.634, -23.279],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 30.705], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.962],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 3.962],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [108.459, -30.455],
                        [-108.459, -30.455],
                        [-115.634, -23.279],
                        [-115.634, 10.908],
                        [-108.459, 18.083],
                        [44.708, 18.083],
                        [54.724, 30.455],
                        [66.508, 18.083],
                        [108.459, 18.083],
                        [115.634, 10.908],
                        [115.634, -23.279],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 37.367], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 58.0000023623884,
          op: 209.000008512745,
          st: 58.0000023623884,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Doctor text Outlines 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 131,
                  s: [100],
                },
                { t: 140.000005702317, s: [2] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 106,
                  s: [464.126, 728.305, 0],
                  to: [0, -14, 0],
                  ti: [0, 14, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 116,
                  s: [464.126, 644.305, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 131,
                  s: [464.126, 644.305, 0],
                  to: [0, -19.167, 0],
                  ti: [0, 19.167, 0],
                },
                { t: 140.000005702317, s: [464.126, 529.305, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [60.884, 65.911, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 85,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 92,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 95,
                  s: [95, 95, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 98,
                  s: [105, 105, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 100,
                  s: [98, 98, 100],
                },
                { t: 102.000004154545, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [5.83, 0],
                        [0, -5.829],
                      ],
                      o: [
                        [0, -5.829],
                        [-5.83, 0],
                        [0, 0],
                      ],
                      v: [
                        [10.556, 5.278],
                        [-0.001, -5.278],
                        [-10.556, 5.278],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [202.866, 28.768], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 51',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.206],
                        [3.206, 0],
                        [0, 3.206],
                        [-3.207, 0],
                      ],
                      o: [
                        [0, 3.206],
                        [-3.207, 0],
                        [0, -3.206],
                        [3.206, 0],
                      ],
                      v: [
                        [5.806, 0],
                        [-0.001, 5.806],
                        [-5.806, 0],
                        [-0.001, -5.806],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [202.69, 14.166], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 102',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-26.172, -1.428],
                        [26.172, -1.428],
                        [26.172, 1.428],
                        [-26.172, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [147.29, 31.18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 103',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.427],
                        [72.806, -1.427],
                        [72.806, 1.427],
                        [-72.806, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 25.787], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 104',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.428],
                        [72.806, -1.428],
                        [72.806, 1.428],
                        [-72.806, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 20.394], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 105',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.427],
                        [72.806, -1.427],
                        [72.806, 1.427],
                        [-72.806, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 15.001], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 106',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [-108.459, -30.455],
                        [108.459, -30.455],
                        [115.634, -23.28],
                        [115.634, 10.907],
                        [108.459, 18.082],
                        [-44.709, 18.082],
                        [-54.724, 30.455],
                        [-66.507, 18.082],
                        [-108.459, 18.082],
                        [-115.634, 10.907],
                        [-115.634, -23.28],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 30.705], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 107',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [-108.459, -30.455],
                        [108.459, -30.455],
                        [115.634, -23.28],
                        [115.634, 10.907],
                        [108.459, 18.082],
                        [-44.709, 18.082],
                        [-54.724, 30.455],
                        [-66.507, 18.082],
                        [-108.459, 18.082],
                        [-115.634, 10.907],
                        [-115.634, -23.28],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 37.367], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 108',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 85.000003462121,
          op: 236.000009612477,
          st: 85.000003462121,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Love text Outlines 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 133,
                  s: [100],
                },
                { t: 143.000005824509, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 133,
                  s: [580.843, 729.556, 0],
                  to: [0, -13.917, 0],
                  ti: [0, 13.917, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 143,
                  s: [580.843, 646.056, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 159,
                  s: [580.843, 646.056, 0],
                  to: [0, -21.333, 0],
                  ti: [0, 21.333, 0],
                },
                { t: 169.000006883511, s: [580.843, 518.056, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [170.884, 67.411, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 112,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 119,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 122,
                  s: [95, 95, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 125,
                  s: [105, 105, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 127,
                  s: [98, 98, 100],
                },
                { t: 129.000005254278, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [26.173, -1.427],
                        [-26.173, -1.427],
                        [-26.173, 1.427],
                        [26.173, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [84.477, 31.181], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.807, -1.428],
                        [-72.807, -1.428],
                        [-72.807, 1.428],
                        [72.807, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [131.112, 25.788], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.807, -1.427],
                        [-72.807, -1.427],
                        [-72.807, 1.427],
                        [72.807, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [131.112, 20.395], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.807, -1.428],
                        [-72.807, -1.428],
                        [-72.807, 1.428],
                        [72.807, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [131.112, 15.002], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.513],
                        [4.29, 0],
                        [0.236, -4.077],
                        [0, 0],
                        [0, 0],
                        [4.137, 0],
                        [0, -4.29],
                        [-0.095, -0.486],
                        [0, 0],
                        [-13.708, -5.483],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -4.29],
                        [-4.136, 0],
                        [0, 0],
                        [0, 0],
                        [-0.236, -4.077],
                        [-4.29, 0],
                        [0, 0.513],
                        [0, 0],
                        [0, 0],
                        [13.707, -5.483],
                        [0, 0],
                        [0.094, -0.486],
                      ],
                      v: [
                        [15.535, -5.026],
                        [7.767, -13.707],
                        [0.023, -6.393],
                        [0, -6.396],
                        [-0.023, -6.393],
                        [-7.768, -13.707],
                        [-15.535, -5.026],
                        [-15.388, -3.526],
                        [-15.389, -3.526],
                        [0, 13.707],
                        [15.389, -3.526],
                        [15.388, -3.526],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [30.397, 23.586], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [108.459, -30.455],
                        [-108.459, -30.455],
                        [-115.634, -23.279],
                        [-115.634, 10.908],
                        [-108.459, 18.083],
                        [44.708, 18.083],
                        [54.724, 30.455],
                        [66.508, 18.083],
                        [108.459, 18.083],
                        [115.634, 10.908],
                        [115.634, -23.279],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 30.705], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.962],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, 3.962],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [108.459, -30.455],
                        [-108.459, -30.455],
                        [-115.634, -23.279],
                        [-115.634, 10.908],
                        [-108.459, 18.083],
                        [44.708, 18.083],
                        [54.724, 30.455],
                        [66.508, 18.083],
                        [108.459, 18.083],
                        [115.634, 10.908],
                        [115.634, -23.279],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 37.367], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 112.000004561854,
          op: 263.00001071221,
          st: 112.000004561854,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Doctor text Outlines 4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 183,
                  s: [100],
                },
                { t: 193.000007861051, s: [2] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 156,
                  s: [464.126, 728.305, 0],
                  to: [0, -14, 0],
                  ti: [0, 14, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 166,
                  s: [464.126, 644.305, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 183,
                  s: [464.126, 644.305, 0],
                  to: [0, -19.167, 0],
                  ti: [0, 19.167, 0],
                },
                { t: 193.000007861051, s: [464.126, 529.305, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [60.884, 65.911, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 135,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 142,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 145,
                  s: [95, 95, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 148,
                  s: [105, 105, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 150,
                  s: [98, 98, 100],
                },
                { t: 152.000006191087, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [5.83, 0],
                        [0, -5.829],
                      ],
                      o: [
                        [0, -5.829],
                        [-5.83, 0],
                        [0, 0],
                      ],
                      v: [
                        [10.556, 5.278],
                        [-0.001, -5.278],
                        [-10.556, 5.278],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [202.866, 28.768], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 51',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.206],
                        [3.206, 0],
                        [0, 3.206],
                        [-3.207, 0],
                      ],
                      o: [
                        [0, 3.206],
                        [-3.207, 0],
                        [0, -3.206],
                        [3.206, 0],
                      ],
                      v: [
                        [5.806, 0],
                        [-0.001, 5.806],
                        [-5.806, 0],
                        [-0.001, -5.806],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [202.69, 14.166], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 102',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-26.172, -1.428],
                        [26.172, -1.428],
                        [26.172, 1.428],
                        [-26.172, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [147.29, 31.18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 103',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.427],
                        [72.806, -1.427],
                        [72.806, 1.427],
                        [-72.806, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 25.787], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 104',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.428],
                        [72.806, -1.428],
                        [72.806, 1.428],
                        [-72.806, 1.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 20.394], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 105',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-72.806, -1.427],
                        [72.806, -1.427],
                        [72.806, 1.427],
                        [-72.806, 1.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.656, 15.001], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 106',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [-108.459, -30.455],
                        [108.459, -30.455],
                        [115.634, -23.28],
                        [115.634, 10.907],
                        [108.459, 18.082],
                        [-44.709, 18.082],
                        [-54.724, 30.455],
                        [-66.507, 18.082],
                        [-108.459, 18.082],
                        [-115.634, 10.907],
                        [-115.634, -23.28],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 30.705], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 107',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.962, 0],
                        [0, 0],
                        [0, 3.963],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.963, 0],
                        [0, 0],
                        [0, -3.963],
                      ],
                      v: [
                        [-108.459, -30.455],
                        [108.459, -30.455],
                        [115.634, -23.28],
                        [115.634, 10.907],
                        [108.459, 18.082],
                        [-44.709, 18.082],
                        [-54.724, 30.455],
                        [-66.507, 18.082],
                        [-108.459, 18.082],
                        [-115.634, 10.907],
                        [-115.634, -23.28],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941, 0.1568627450980392,
                      0.34901960784313724, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [115.884, 37.367], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 108',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 135.000005498663,
          op: 286.000011649019,
          st: 135.000005498663,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'DNA big 2 Outlines',
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: -180.417, ix: 10 },
            p: { a: 0, k: [-4.239, 1230.858, 0], ix: 2 },
            a: { a: 0, k: [273.583, 441.862, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-119.016, -42.404],
                        [-123.031, -28.73],
                        [119.016, 42.405],
                        [123.031, 28.729],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [204.807, 593.448], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [14.061, -1.266],
                        [23.645, 31.221],
                        [0, 0],
                        [0.125, -0.015],
                      ],
                      o: [
                        [-7.507, -6.699],
                        [0, 0],
                        [-41.996, 11.016],
                        [-1.542, -0.004],
                      ],
                      v: [
                        [73.978, -310.292],
                        [16.413, -372.573],
                        [179.346, -324.984],
                        [98.918, -311.589],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-28.985, 3.642],
                        [-22.484, -41.518],
                      ],
                      o: [
                        [34.896, -21.286],
                        [14.561, 12.359],
                        [0, 0],
                      ],
                      v: [
                        [-40.667, -246.628],
                        [65.213, -280.468],
                        [137.013, -194.41],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [9.554, -10.502],
                        [0, 0],
                        [-3.904, 16.471],
                        [0, 0],
                        [0.298, -1.246],
                      ],
                      o: [
                        [0, 0],
                        [-4.709, -15.899],
                        [0, 0],
                        [-0.253, 1.237],
                        [-3.622, 15.137],
                      ],
                      v: [
                        [133.913, -58.068],
                        [-95.413, -126.532],
                        [-97.549, -175.377],
                        [154.875, -100.014],
                        [154.303, -96.301],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [34.76, -2.774],
                        [22.032, 49.337],
                        [0, 0],
                      ],
                      o: [
                        [-16.078, -12.347],
                        [0, 0],
                        [-35.022, 27.925],
                      ],
                      v: [
                        [-1.392, -7.171],
                        [-89.194, -109.786],
                        [121.834, -46.784],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.151, -32.709],
                        [0, 0],
                        [-44.982, 3.009],
                        [-13.226, 0.655],
                      ],
                      o: [
                        [0, 0],
                        [27.739, -20.3],
                        [3.006, 0.096],
                        [33.022, 29.627],
                      ],
                      v: [
                        [53.324, 117.037],
                        [-146.474, 58.314],
                        [-36.089, 22.51],
                        [-10.474, 22.134],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.762, -18.126],
                        [12.461, -13.01],
                        [0, 0],
                        [-6.069, 16.953],
                        [-14.241, 13.423],
                        [0, 0],
                      ],
                      o: [
                        [-5.541, 17.442],
                        [0, 0],
                        [-1.857, -17.62],
                        [6.546, -18.288],
                        [0, 0],
                        [2.937, 18.903],
                      ],
                      v: [
                        [52.751, 188.736],
                        [25.217, 234.79],
                        [-197.233, 169.414],
                        [-191.159, 116.971],
                        [-159.626, 69.304],
                        [56.797, 132.909],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [26.846, -2.707],
                        [20.335, -4.059],
                        [11.374, 10.65],
                        [5.874, 24.826],
                        [0, 0],
                      ],
                      o: [
                        [-23.013, 2.328],
                        [-9.929, -10.624],
                        [-20.148, -18.862],
                        [0, 0],
                        [-19.53, 15.587],
                      ],
                      v: [
                        [-58.01, 274.554],
                        [-123.203, 284.379],
                        [-155.141, 252.38],
                        [-194.433, 185.09],
                        [12.805, 245.997],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-11.812, -16.175],
                        [0, 0],
                        [-27.742, 6.243],
                      ],
                      o: [
                        [0, 0],
                        [19.832, -6.747],
                        [16.243, 18.276],
                      ],
                      v: [
                        [-91.252, 367.931],
                        [-205.207, 335.995],
                        [-133.291, 315.767],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-15.351, 11.996],
                        [0, 0],
                        [1.589, -21.924],
                        [0, 0],
                        [-0.206, 0.469],
                      ],
                      o: [
                        [0, 0],
                        [8.322, 19.726],
                        [0, 0],
                        [0.2, -0.468],
                        [8.381, -18.95],
                      ],
                      v: [
                        [-55.733, -236.205],
                        [145.284, -177.128],
                        [157.058, -114.255],
                        [-93.115, -188.942],
                        [-92.575, -190.354],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-54.216, 41.787],
                        [0, 0],
                        [18.952, -6.609],
                        [0, 0],
                        [4.589, 17.103],
                        [0, 0],
                        [-26.914, -26.618],
                        [29.816, -67.342],
                        [-42.82, -38.719],
                        [23.095, -64.491],
                        [-46.752, -43.765],
                        [-6.073, -6.182],
                        [2.645, -1.308],
                        [0, 0],
                        [-20.404, 7.811],
                        [0, 0],
                        [-0.219, -0.441],
                        [0, 0],
                        [17.387, 26.728],
                        [0, 0],
                        [0, 0],
                        [22.255, 26.189],
                        [-15.916, 1.607],
                        [-17.424, 8.624],
                        [-12.465, 39.204],
                        [52.644, 55.567],
                        [-17.901, 74.657],
                        [9.872, 27.37],
                        [0, 0],
                        [0, 0],
                        [22.508, 22.096],
                      ],
                      o: [
                        [0, 0],
                        [-14.581, 11.226],
                        [0, 0],
                        [-10.529, -16.217],
                        [0, 0],
                        [13.942, 51.963],
                        [-53.354, 10.039],
                        [-35.484, 80.244],
                        [-87.749, 7.479],
                        [-20.403, 56.973],
                        [6.564, 6.142],
                        [-69.614, 17.29],
                        [0, 0],
                        [0.317, -0.157],
                        [0, 0],
                        [22.161, 32.487],
                        [0, 0],
                        [-0.81, -1.639],
                        [0, 0],
                        [0, 0],
                        [-13.153, -20.021],
                        [14.681, -2.569],
                        [20.404, -2.065],
                        [36.992, -18.308],
                        [18.858, -59.327],
                        [58.094, -8.362],
                        [7.529, -31.43],
                        [0, 0],
                        [0, 0],
                        [-16.611, -42.463],
                        [21.861, -1.688],
                      ],
                      v: [
                        [273.583, -336.382],
                        [256.163, -358.959],
                        [204.278, -332.568],
                        [3.272, -391.28],
                        [-20.715, -441.862],
                        [-48.259, -434.478],
                        [37.774, -305.264],
                        [-118.671, -201.893],
                        [-43.847, -5.396],
                        [-218.013, 107.361],
                        [-174.632, 273.211],
                        [-155.766, 291.72],
                        [-273.583, 332.502],
                        [-260.932, 358.064],
                        [-228.363, 344.334],
                        [-78.142, 386.432],
                        [-44.441, 441.862],
                        [-18.889, 429.2],
                        [-46.082, 382.87],
                        [-45.488, 380.757],
                        [-47.945, 380.07],
                        [-101.048, 309.327],
                        [-55.147, 302.937],
                        [1.978, 286.597],
                        [79.931, 197.379],
                        [26.744, 18.552],
                        [182.052, -89.647],
                        [175.699, -178.599],
                        [176.903, -182.686],
                        [173.847, -183.585],
                        [104.409, -283.431],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [273.583, 441.862], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 12,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1949.0000793844,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'DNA big Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: -47.189, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [806.416, 225.218, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.333, y: 0 },
                  t: 75,
                  s: [742.416, 81.218, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 150.000006109625, s: [806.416, 225.218, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [273.583, 441.862, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-119.016, -42.404],
                        [-123.031, -28.73],
                        [119.016, 42.405],
                        [123.031, 28.729],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [204.807, 593.448], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [14.061, -1.266],
                        [23.645, 31.221],
                        [0, 0],
                        [0.125, -0.015],
                      ],
                      o: [
                        [-7.507, -6.699],
                        [0, 0],
                        [-41.996, 11.016],
                        [-1.542, -0.004],
                      ],
                      v: [
                        [73.978, -310.292],
                        [16.413, -372.573],
                        [179.346, -324.984],
                        [98.918, -311.589],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-28.985, 3.642],
                        [-22.484, -41.518],
                      ],
                      o: [
                        [34.896, -21.286],
                        [14.561, 12.359],
                        [0, 0],
                      ],
                      v: [
                        [-40.667, -246.628],
                        [65.213, -280.468],
                        [137.013, -194.41],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [9.554, -10.502],
                        [0, 0],
                        [-3.904, 16.471],
                        [0, 0],
                        [0.298, -1.246],
                      ],
                      o: [
                        [0, 0],
                        [-4.709, -15.899],
                        [0, 0],
                        [-0.253, 1.237],
                        [-3.622, 15.137],
                      ],
                      v: [
                        [133.913, -58.068],
                        [-95.413, -126.532],
                        [-97.549, -175.377],
                        [154.875, -100.014],
                        [154.303, -96.301],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [34.76, -2.774],
                        [22.032, 49.337],
                        [0, 0],
                      ],
                      o: [
                        [-16.078, -12.347],
                        [0, 0],
                        [-35.022, 27.925],
                      ],
                      v: [
                        [-1.392, -7.171],
                        [-89.194, -109.786],
                        [121.834, -46.784],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.151, -32.709],
                        [0, 0],
                        [-44.982, 3.009],
                        [-13.226, 0.655],
                      ],
                      o: [
                        [0, 0],
                        [27.739, -20.3],
                        [3.006, 0.096],
                        [33.022, 29.627],
                      ],
                      v: [
                        [53.324, 117.037],
                        [-146.474, 58.314],
                        [-36.089, 22.51],
                        [-10.474, 22.134],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.762, -18.126],
                        [12.461, -13.01],
                        [0, 0],
                        [-6.069, 16.953],
                        [-14.241, 13.423],
                        [0, 0],
                      ],
                      o: [
                        [-5.541, 17.442],
                        [0, 0],
                        [-1.857, -17.62],
                        [6.546, -18.288],
                        [0, 0],
                        [2.937, 18.903],
                      ],
                      v: [
                        [52.751, 188.736],
                        [25.217, 234.79],
                        [-197.233, 169.414],
                        [-191.159, 116.971],
                        [-159.626, 69.304],
                        [56.797, 132.909],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [26.846, -2.707],
                        [20.335, -4.059],
                        [11.374, 10.65],
                        [5.874, 24.826],
                        [0, 0],
                      ],
                      o: [
                        [-23.013, 2.328],
                        [-9.929, -10.624],
                        [-20.148, -18.862],
                        [0, 0],
                        [-19.53, 15.587],
                      ],
                      v: [
                        [-58.01, 274.554],
                        [-123.203, 284.379],
                        [-155.141, 252.38],
                        [-194.433, 185.09],
                        [12.805, 245.997],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-11.812, -16.175],
                        [0, 0],
                        [-27.742, 6.243],
                      ],
                      o: [
                        [0, 0],
                        [19.832, -6.747],
                        [16.243, 18.276],
                      ],
                      v: [
                        [-91.252, 367.931],
                        [-205.207, 335.995],
                        [-133.291, 315.767],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-15.351, 11.996],
                        [0, 0],
                        [1.589, -21.924],
                        [0, 0],
                        [-0.206, 0.469],
                      ],
                      o: [
                        [0, 0],
                        [8.322, 19.726],
                        [0, 0],
                        [0.2, -0.468],
                        [8.381, -18.95],
                      ],
                      v: [
                        [-55.733, -236.205],
                        [145.284, -177.128],
                        [157.058, -114.255],
                        [-93.115, -188.942],
                        [-92.575, -190.354],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-54.216, 41.787],
                        [0, 0],
                        [18.952, -6.609],
                        [0, 0],
                        [4.589, 17.103],
                        [0, 0],
                        [-26.914, -26.618],
                        [29.816, -67.342],
                        [-42.82, -38.719],
                        [23.095, -64.491],
                        [-46.752, -43.765],
                        [-6.073, -6.182],
                        [2.645, -1.308],
                        [0, 0],
                        [-20.404, 7.811],
                        [0, 0],
                        [-0.219, -0.441],
                        [0, 0],
                        [17.387, 26.728],
                        [0, 0],
                        [0, 0],
                        [22.255, 26.189],
                        [-15.916, 1.607],
                        [-17.424, 8.624],
                        [-12.465, 39.204],
                        [52.644, 55.567],
                        [-17.901, 74.657],
                        [9.872, 27.37],
                        [0, 0],
                        [0, 0],
                        [22.508, 22.096],
                      ],
                      o: [
                        [0, 0],
                        [-14.581, 11.226],
                        [0, 0],
                        [-10.529, -16.217],
                        [0, 0],
                        [13.942, 51.963],
                        [-53.354, 10.039],
                        [-35.484, 80.244],
                        [-87.749, 7.479],
                        [-20.403, 56.973],
                        [6.564, 6.142],
                        [-69.614, 17.29],
                        [0, 0],
                        [0.317, -0.157],
                        [0, 0],
                        [22.161, 32.487],
                        [0, 0],
                        [-0.81, -1.639],
                        [0, 0],
                        [0, 0],
                        [-13.153, -20.021],
                        [14.681, -2.569],
                        [20.404, -2.065],
                        [36.992, -18.308],
                        [18.858, -59.327],
                        [58.094, -8.362],
                        [7.529, -31.43],
                        [0, 0],
                        [0, 0],
                        [-16.611, -42.463],
                        [21.861, -1.688],
                      ],
                      v: [
                        [273.583, -336.382],
                        [256.163, -358.959],
                        [204.278, -332.568],
                        [3.272, -391.28],
                        [-20.715, -441.862],
                        [-48.259, -434.478],
                        [37.774, -305.264],
                        [-118.671, -201.893],
                        [-43.847, -5.396],
                        [-218.013, 107.361],
                        [-174.632, 273.211],
                        [-155.766, 291.72],
                        [-273.583, 332.502],
                        [-260.932, 358.064],
                        [-228.363, 344.334],
                        [-78.142, 386.432],
                        [-44.441, 441.862],
                        [-18.889, 429.2],
                        [-46.082, 382.87],
                        [-45.488, 380.757],
                        [-47.945, 380.07],
                        [-101.048, 309.327],
                        [-55.147, 302.937],
                        [1.978, 286.597],
                        [79.931, 197.379],
                        [26.744, 18.552],
                        [182.052, -89.647],
                        [175.699, -178.599],
                        [176.903, -182.686],
                        [173.847, -183.585],
                        [104.409, -283.431],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [273.583, 441.862], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 11,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1949.0000793844,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'DNA small 2 Outlines',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: -180.175, ix: 10 },
            p: { a: 0, k: [-50.633, -129.204, 0], ix: 2 },
            a: { a: 0, k: [182.367, 218.796, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-54.742, 35.708],
                        [-50.371, 41.646],
                        [54.742, -35.71],
                        [50.369, -41.645],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [234.826, 293.805], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.884, -6.185],
                        [20.259, 0.046],
                        [0, 0],
                        [0.033, -0.056],
                      ],
                      o: [
                        [-5.109, 0.991],
                        [0, 0],
                        [-8.622, 20.739],
                        [-0.485, 0.633],
                      ],
                      v: [
                        [-92.951, -128.455],
                        [-136.623, -124.269],
                        [-65.989, -176.433],
                        [-85.672, -139.128],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-7.581, 13.072],
                        [-24.136, -3.751],
                      ],
                      o: [
                        [2.17, -21.034],
                        [9.649, -2.123],
                        [0, 0],
                      ],
                      v: [
                        [-102.66, -61.316],
                        [-83.42, -115.503],
                        [-25.5, -118.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.329, -7.224],
                        [0, 0],
                        [5.557, 6.767],
                        [0, 0],
                        [-0.42, -0.513],
                      ],
                      o: [
                        [0, 0],
                        [-8.02, -3.042],
                        [0, 0],
                        [0.429, 0.492],
                        [5.097, 6.233],
                      ],
                      v: [
                        [29.653, -74.109],
                        [-70.373, -1.156],
                        [-91.15, -15.579],
                        [18.954, -95.88],
                        [20.303, -94.481],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [9.747, -15.177],
                        [27.211, 6.387],
                        [0, 0],
                      ],
                      o: [
                        [-10.12, 2.75],
                        [0, 0],
                        [0.523, 23.165],
                      ],
                      v: [
                        [8.217, -2.466],
                        [-61.531, 1.53],
                        [30.515, -65.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-16.331, -6.48],
                        [0, 0],
                        [-12.853, 19.46],
                        [-3.874, 5.65],
                      ],
                      o: [
                        [0, 0],
                        [0.334, -17.779],
                        [0.982, -1.207],
                        [22.542, -4.311],
                      ],
                      v: [
                        [76.488, 13.922],
                        [-10.278, 77.773],
                        [9.565, 21.115],
                        [17.435, 10.453],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.656, -8.051],
                        [-1.453, -9.206],
                        [0, 0],
                        [5.077, 7.81],
                        [1.064, 10.067],
                        [0, 0],
                      ],
                      o: [
                        [5.445, 7.745],
                        [0, 0],
                        [-7.834, -4.755],
                        [-5.477, -8.424],
                        [0, 0],
                        [8.702, 4.713],
                      ],
                      v: [
                        [105.824, 36.62],
                        [116.157, 62.383],
                        [19.554, 133.473],
                        [-0.131, 114.543],
                        [-9.874, 86.629],
                        [84.111, 17.465],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.296, -11.9],
                        [4.699, -9.643],
                        [7.947, -1.347],
                        [12.06, 5.36],
                        [0, 0],
                      ],
                      o: [
                        [-6.252, 10.202],
                        [-7.484, 0.758],
                        [-14.076, 2.384],
                        [0, 0],
                        [0.298, 12.923],
                      ],
                      v: [
                        [106.452, 109.101],
                        [90.073, 139.016],
                        [66.894, 142.139],
                        [26.885, 137.232],
                        [116.882, 71.003],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-10.358, -0.205],
                        [0, 0],
                        [-6.121, 13.376],
                      ],
                      o: [
                        [0, 0],
                        [3.436, -10.277],
                        [12.612, -0.96],
                      ],
                      v: [
                        [134.476, 152.038],
                        [85.63, 188.942],
                        [99.833, 153.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.128, 10.078],
                        [0, 0],
                        [-8.528, -7.522],
                        [0, 0],
                        [0.129, 0.232],
                      ],
                      o: [
                        [0, 0],
                        [10.727, 2.754],
                        [0, 0],
                        [-0.13, -0.229],
                        [-5.175, -9.386],
                      ],
                      v: [
                        [-103.089, -51.849],
                        [-15.795, -116.09],
                        [13.776, -101.24],
                        [-95.345, -21.654],
                        [-95.757, -22.319],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.216, 35.409],
                        [0, 0],
                        [3.216, -9.872],
                        [0, 0],
                        [8.478, 3.468],
                        [0, 0],
                        [-19.39, 2.74],
                        [-18.381, -33.371],
                        [-29.353, 5.498],
                        [-19.312, -29.711],
                        [-32.663, 5.535],
                        [-4.448, 0.563],
                        [0.29, -1.499],
                        [0, 0],
                        [-3.176, 10.847],
                        [0, 0],
                        [-0.25, -0.048],
                        [0, 0],
                        [16.45, 1.216],
                        [0, 0],
                        [0, 0],
                        [17.753, -0.957],
                        [-4.325, 7.055],
                        [-1.908, 9.874],
                        [12.234, 17.413],
                        [39.367, -4.264],
                        [25.125, 30.759],
                        [14.359, 4.51],
                        [0, 0],
                        [0, 0],
                        [16.147, -2.343],
                      ],
                      o: [
                        [0, 0],
                        [0.053, 9.519],
                        [0, 0],
                        [-9.974, -0.746],
                        [0, 0],
                        [25.758, 10.54],
                        [-12.582, 25.108],
                        [21.916, 39.746],
                        [-24.412, 38.465],
                        [17.061, 26.248],
                        [4.585, -0.779],
                        [-14.692, 34.073],
                        [0, 0],
                        [0.035, -0.18],
                        [0, 0],
                        [20.315, 1.054],
                        [0, 0],
                        [-0.929, -0.179],
                        [0, 0],
                        [0, 0],
                        [-12.362, -0.858],
                        [3.542, -6.849],
                        [5.542, -9.046],
                        [4.053, -20.964],
                        [-18.514, -26.35],
                        [14.758, -26.535],
                        [-10.579, -12.945],
                        [0, 0],
                        [0, 0],
                        [-22.684, -6.465],
                        [6.154, -9.528],
                      ],
                      v: [
                        [-41.158, -218.796],
                        [-55.909, -218.698],
                        [-61.3, -189.072],
                        [-148.441, -124.721],
                        [-176.778, -130.693],
                        [-182.367, -117.041],
                        [-102.223, -111.976],
                        [-108.682, -15.191],
                        [-4.353, 15.566],
                        [-12.5, 122.587],
                        [69.363, 156.688],
                        [82.893, 154.72],
                        [62.771, 215.996],
                        [77.257, 218.796],
                        [81.808, 201.087],
                        [146.2, 152.438],
                        [179.575, 155.93],
                        [182.368, 141.444],
                        [154.777, 138.124],
                        [154.093, 137.218],
                        [153.041, 138.014],
                        [107.283, 137.711],
                        [119.033, 116.814],
                        [130.202, 88.18],
                        [117.897, 28.14],
                        [27.62, -5.989],
                        [31.735, -103.82],
                        [-6.872, -129.071],
                        [-8.177, -130.847],
                        [-9.505, -129.871],
                        [-72.36, -132.567],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [182.367, 218.796], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 11,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1949.0000793844,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'DNA small 3 Outlines',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: -181.619, ix: 10 },
            p: { a: 0, k: [445.367, 586.796, 0], ix: 2 },
            a: { a: 0, k: [182.367, 218.796, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-54.742, 35.708],
                        [-50.371, 41.646],
                        [54.742, -35.71],
                        [50.369, -41.645],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [234.826, 293.805], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.884, -6.185],
                        [20.259, 0.046],
                        [0, 0],
                        [0.033, -0.056],
                      ],
                      o: [
                        [-5.109, 0.991],
                        [0, 0],
                        [-8.622, 20.739],
                        [-0.485, 0.633],
                      ],
                      v: [
                        [-92.951, -128.455],
                        [-136.623, -124.269],
                        [-65.989, -176.433],
                        [-85.672, -139.128],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-7.581, 13.072],
                        [-24.136, -3.751],
                      ],
                      o: [
                        [2.17, -21.034],
                        [9.649, -2.123],
                        [0, 0],
                      ],
                      v: [
                        [-102.66, -61.316],
                        [-83.42, -115.503],
                        [-25.5, -118.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.329, -7.224],
                        [0, 0],
                        [5.557, 6.767],
                        [0, 0],
                        [-0.42, -0.513],
                      ],
                      o: [
                        [0, 0],
                        [-8.02, -3.042],
                        [0, 0],
                        [0.429, 0.492],
                        [5.097, 6.233],
                      ],
                      v: [
                        [29.653, -74.109],
                        [-70.373, -1.156],
                        [-91.15, -15.579],
                        [18.954, -95.88],
                        [20.303, -94.481],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [9.747, -15.177],
                        [27.211, 6.387],
                        [0, 0],
                      ],
                      o: [
                        [-10.12, 2.75],
                        [0, 0],
                        [0.523, 23.165],
                      ],
                      v: [
                        [8.217, -2.466],
                        [-61.531, 1.53],
                        [30.515, -65.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-16.331, -6.48],
                        [0, 0],
                        [-12.853, 19.46],
                        [-3.874, 5.65],
                      ],
                      o: [
                        [0, 0],
                        [0.334, -17.779],
                        [0.982, -1.207],
                        [22.542, -4.311],
                      ],
                      v: [
                        [76.488, 13.922],
                        [-10.278, 77.773],
                        [9.565, 21.115],
                        [17.435, 10.453],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.656, -8.051],
                        [-1.453, -9.206],
                        [0, 0],
                        [5.077, 7.81],
                        [1.064, 10.067],
                        [0, 0],
                      ],
                      o: [
                        [5.445, 7.745],
                        [0, 0],
                        [-7.834, -4.755],
                        [-5.477, -8.424],
                        [0, 0],
                        [8.702, 4.713],
                      ],
                      v: [
                        [105.824, 36.62],
                        [116.157, 62.383],
                        [19.554, 133.473],
                        [-0.131, 114.543],
                        [-9.874, 86.629],
                        [84.111, 17.465],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.296, -11.9],
                        [4.699, -9.643],
                        [7.947, -1.347],
                        [12.06, 5.36],
                        [0, 0],
                      ],
                      o: [
                        [-6.252, 10.202],
                        [-7.484, 0.758],
                        [-14.076, 2.384],
                        [0, 0],
                        [0.298, 12.923],
                      ],
                      v: [
                        [106.452, 109.101],
                        [90.073, 139.016],
                        [66.894, 142.139],
                        [26.885, 137.232],
                        [116.882, 71.003],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-10.358, -0.205],
                        [0, 0],
                        [-6.121, 13.376],
                      ],
                      o: [
                        [0, 0],
                        [3.436, -10.277],
                        [12.612, -0.96],
                      ],
                      v: [
                        [134.476, 152.038],
                        [85.63, 188.942],
                        [99.833, 153.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.128, 10.078],
                        [0, 0],
                        [-8.528, -7.522],
                        [0, 0],
                        [0.129, 0.232],
                      ],
                      o: [
                        [0, 0],
                        [10.727, 2.754],
                        [0, 0],
                        [-0.13, -0.229],
                        [-5.175, -9.386],
                      ],
                      v: [
                        [-103.089, -51.849],
                        [-15.795, -116.09],
                        [13.776, -101.24],
                        [-95.345, -21.654],
                        [-95.757, -22.319],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.216, 35.409],
                        [0, 0],
                        [3.216, -9.872],
                        [0, 0],
                        [8.478, 3.468],
                        [0, 0],
                        [-19.39, 2.74],
                        [-18.381, -33.371],
                        [-29.353, 5.498],
                        [-19.312, -29.711],
                        [-32.663, 5.535],
                        [-4.448, 0.563],
                        [0.29, -1.499],
                        [0, 0],
                        [-3.176, 10.847],
                        [0, 0],
                        [-0.25, -0.048],
                        [0, 0],
                        [16.45, 1.216],
                        [0, 0],
                        [0, 0],
                        [17.753, -0.957],
                        [-4.325, 7.055],
                        [-1.908, 9.874],
                        [12.234, 17.413],
                        [39.367, -4.264],
                        [25.125, 30.759],
                        [14.359, 4.51],
                        [0, 0],
                        [0, 0],
                        [16.147, -2.343],
                      ],
                      o: [
                        [0, 0],
                        [0.053, 9.519],
                        [0, 0],
                        [-9.974, -0.746],
                        [0, 0],
                        [25.758, 10.54],
                        [-12.582, 25.108],
                        [21.916, 39.746],
                        [-24.412, 38.465],
                        [17.061, 26.248],
                        [4.585, -0.779],
                        [-14.692, 34.073],
                        [0, 0],
                        [0.035, -0.18],
                        [0, 0],
                        [20.315, 1.054],
                        [0, 0],
                        [-0.929, -0.179],
                        [0, 0],
                        [0, 0],
                        [-12.362, -0.858],
                        [3.542, -6.849],
                        [5.542, -9.046],
                        [4.053, -20.964],
                        [-18.514, -26.35],
                        [14.758, -26.535],
                        [-10.579, -12.945],
                        [0, 0],
                        [0, 0],
                        [-22.684, -6.465],
                        [6.154, -9.528],
                      ],
                      v: [
                        [-41.158, -218.796],
                        [-55.909, -218.698],
                        [-61.3, -189.072],
                        [-148.441, -124.721],
                        [-176.778, -130.693],
                        [-182.367, -117.041],
                        [-102.223, -111.976],
                        [-108.682, -15.191],
                        [-4.353, 15.566],
                        [-12.5, 122.587],
                        [69.363, 156.688],
                        [82.893, 154.72],
                        [62.771, 215.996],
                        [77.257, 218.796],
                        [81.808, 201.087],
                        [146.2, 152.438],
                        [179.575, 155.93],
                        [182.368, 141.444],
                        [154.777, 138.124],
                        [154.093, 137.218],
                        [153.041, 138.014],
                        [107.283, 137.711],
                        [119.033, 116.814],
                        [130.202, 88.18],
                        [117.897, 28.14],
                        [27.62, -5.989],
                        [31.735, -103.82],
                        [-6.872, -129.071],
                        [-8.177, -130.847],
                        [-9.505, -129.871],
                        [-72.36, -132.567],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [182.367, 218.796], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 11,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1949.0000793844,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'DNA small Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 5.629, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [141.718, 625.194, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.333, y: 0 },
                  t: 75,
                  s: [181.718, 693.194, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 150.000006109625, s: [141.718, 625.194, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [182.367, 218.796, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-54.742, 35.708],
                        [-50.371, 41.646],
                        [54.742, -35.71],
                        [50.369, -41.645],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [234.826, 293.805], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.884, -6.185],
                        [20.259, 0.046],
                        [0, 0],
                        [0.033, -0.056],
                      ],
                      o: [
                        [-5.109, 0.991],
                        [0, 0],
                        [-8.622, 20.739],
                        [-0.485, 0.633],
                      ],
                      v: [
                        [-92.951, -128.455],
                        [-136.623, -124.269],
                        [-65.989, -176.433],
                        [-85.672, -139.128],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-7.581, 13.072],
                        [-24.136, -3.751],
                      ],
                      o: [
                        [2.17, -21.034],
                        [9.649, -2.123],
                        [0, 0],
                      ],
                      v: [
                        [-102.66, -61.316],
                        [-83.42, -115.503],
                        [-25.5, -118.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.329, -7.224],
                        [0, 0],
                        [5.557, 6.767],
                        [0, 0],
                        [-0.42, -0.513],
                      ],
                      o: [
                        [0, 0],
                        [-8.02, -3.042],
                        [0, 0],
                        [0.429, 0.492],
                        [5.097, 6.233],
                      ],
                      v: [
                        [29.653, -74.109],
                        [-70.373, -1.156],
                        [-91.15, -15.579],
                        [18.954, -95.88],
                        [20.303, -94.481],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [9.747, -15.177],
                        [27.211, 6.387],
                        [0, 0],
                      ],
                      o: [
                        [-10.12, 2.75],
                        [0, 0],
                        [0.523, 23.165],
                      ],
                      v: [
                        [8.217, -2.466],
                        [-61.531, 1.53],
                        [30.515, -65.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-16.331, -6.48],
                        [0, 0],
                        [-12.853, 19.46],
                        [-3.874, 5.65],
                      ],
                      o: [
                        [0, 0],
                        [0.334, -17.779],
                        [0.982, -1.207],
                        [22.542, -4.311],
                      ],
                      v: [
                        [76.488, 13.922],
                        [-10.278, 77.773],
                        [9.565, 21.115],
                        [17.435, 10.453],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.656, -8.051],
                        [-1.453, -9.206],
                        [0, 0],
                        [5.077, 7.81],
                        [1.064, 10.067],
                        [0, 0],
                      ],
                      o: [
                        [5.445, 7.745],
                        [0, 0],
                        [-7.834, -4.755],
                        [-5.477, -8.424],
                        [0, 0],
                        [8.702, 4.713],
                      ],
                      v: [
                        [105.824, 36.62],
                        [116.157, 62.383],
                        [19.554, 133.473],
                        [-0.131, 114.543],
                        [-9.874, 86.629],
                        [84.111, 17.465],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.296, -11.9],
                        [4.699, -9.643],
                        [7.947, -1.347],
                        [12.06, 5.36],
                        [0, 0],
                      ],
                      o: [
                        [-6.252, 10.202],
                        [-7.484, 0.758],
                        [-14.076, 2.384],
                        [0, 0],
                        [0.298, 12.923],
                      ],
                      v: [
                        [106.452, 109.101],
                        [90.073, 139.016],
                        [66.894, 142.139],
                        [26.885, 137.232],
                        [116.882, 71.003],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-10.358, -0.205],
                        [0, 0],
                        [-6.121, 13.376],
                      ],
                      o: [
                        [0, 0],
                        [3.436, -10.277],
                        [12.612, -0.96],
                      ],
                      v: [
                        [134.476, 152.038],
                        [85.63, 188.942],
                        [99.833, 153.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.128, 10.078],
                        [0, 0],
                        [-8.528, -7.522],
                        [0, 0],
                        [0.129, 0.232],
                      ],
                      o: [
                        [0, 0],
                        [10.727, 2.754],
                        [0, 0],
                        [-0.13, -0.229],
                        [-5.175, -9.386],
                      ],
                      v: [
                        [-103.089, -51.849],
                        [-15.795, -116.09],
                        [13.776, -101.24],
                        [-95.345, -21.654],
                        [-95.757, -22.319],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.216, 35.409],
                        [0, 0],
                        [3.216, -9.872],
                        [0, 0],
                        [8.478, 3.468],
                        [0, 0],
                        [-19.39, 2.74],
                        [-18.381, -33.371],
                        [-29.353, 5.498],
                        [-19.312, -29.711],
                        [-32.663, 5.535],
                        [-4.448, 0.563],
                        [0.29, -1.499],
                        [0, 0],
                        [-3.176, 10.847],
                        [0, 0],
                        [-0.25, -0.048],
                        [0, 0],
                        [16.45, 1.216],
                        [0, 0],
                        [0, 0],
                        [17.753, -0.957],
                        [-4.325, 7.055],
                        [-1.908, 9.874],
                        [12.234, 17.413],
                        [39.367, -4.264],
                        [25.125, 30.759],
                        [14.359, 4.51],
                        [0, 0],
                        [0, 0],
                        [16.147, -2.343],
                      ],
                      o: [
                        [0, 0],
                        [0.053, 9.519],
                        [0, 0],
                        [-9.974, -0.746],
                        [0, 0],
                        [25.758, 10.54],
                        [-12.582, 25.108],
                        [21.916, 39.746],
                        [-24.412, 38.465],
                        [17.061, 26.248],
                        [4.585, -0.779],
                        [-14.692, 34.073],
                        [0, 0],
                        [0.035, -0.18],
                        [0, 0],
                        [20.315, 1.054],
                        [0, 0],
                        [-0.929, -0.179],
                        [0, 0],
                        [0, 0],
                        [-12.362, -0.858],
                        [3.542, -6.849],
                        [5.542, -9.046],
                        [4.053, -20.964],
                        [-18.514, -26.35],
                        [14.758, -26.535],
                        [-10.579, -12.945],
                        [0, 0],
                        [0, 0],
                        [-22.684, -6.465],
                        [6.154, -9.528],
                      ],
                      v: [
                        [-41.158, -218.796],
                        [-55.909, -218.698],
                        [-61.3, -189.072],
                        [-148.441, -124.721],
                        [-176.778, -130.693],
                        [-182.367, -117.041],
                        [-102.223, -111.976],
                        [-108.682, -15.191],
                        [-4.353, 15.566],
                        [-12.5, 122.587],
                        [69.363, 156.688],
                        [82.893, 154.72],
                        [62.771, 215.996],
                        [77.257, 218.796],
                        [81.808, 201.087],
                        [146.2, 152.438],
                        [179.575, 155.93],
                        [182.368, 141.444],
                        [154.777, 138.124],
                        [154.093, 137.218],
                        [153.041, 138.014],
                        [107.283, 137.711],
                        [119.033, 116.814],
                        [130.202, 88.18],
                        [117.897, 28.14],
                        [27.62, -5.989],
                        [31.735, -103.82],
                        [-6.872, -129.071],
                        [-8.177, -130.847],
                        [-9.505, -129.871],
                        [-72.36, -132.567],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [182.367, 218.796], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 11,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1949.0000793844,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'L_Hand Outlines',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 16,
              s: [-3.074],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 52,
              s: [1.016],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 91,
              s: [-4.764],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 124,
              s: [-1.186],
            },
            { t: 150.000006109625, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [230.264, 3.466, 0], ix: 2 },
        a: { a: 0, k: [149.349, 0.163, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [28.553, 19.986],
                    [-13.324, -12.372],
                    [5.711, -10.469],
                    [7.614, 0],
                    [6.662, 3.807],
                    [-2.856, 6.663],
                  ],
                  o: [
                    [0, 0],
                    [13.324, 12.373],
                    [-5.71, 10.468],
                    [0, 0],
                    [0, 0],
                    [2.855, -6.661],
                  ],
                  v: [
                    [-17.845, -80.183],
                    [37.354, -57.342],
                    [54.486, 52.108],
                    [-53.535, 80.183],
                    [-60.197, 50.679],
                    [11.658, 32.121],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.8666666666666667, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [167.039, 80.433], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [11.374, 1.441],
                    [0, 0],
                    [4.758, 3.806],
                    [-4.758, -3.807],
                    [-2.855, -1.903],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-4.759, -3.808],
                    [4.759, 3.807],
                    [2.855, 1.904],
                    [0, 0],
                  ],
                  v: [
                    [21.89, -1.904],
                    [3.76, -13.785],
                    [6.347, -5.188],
                    [-18.082, -13.324],
                    [-16.179, 4.759],
                    [-0.951, 15.228],
                    [22.841, 14.275],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.776000019148, 0.728999956916, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [90.188, 138.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-54.724, -49.014],
                    [25.221, -49.014],
                    [54.724, 49.014],
                    [-25.221, 49.014],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [54.974, 128.257], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 151.000006150356,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Head Outlines',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 29,
              s: [-9.232],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 60,
              s: [-9.232],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 90,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0] },
              t: 120,
              s: [-2.032],
            },
            { t: 150.000006109625, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [225.55, 3.685, 0], ix: 2 },
        a: { a: 0, k: [79.539, 126.816, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.264, -4.602],
                    [2.928, 1.441],
                    [-2.263, 4.602],
                    [-2.928, -1.441],
                  ],
                  o: [
                    [-2.264, 4.602],
                    [-2.928, -1.44],
                    [2.263, -4.601],
                    [2.929, 1.44],
                  ],
                  v: [
                    [5.302, 2.607],
                    [-4.099, 8.332],
                    [-5.302, -2.608],
                    [4.097, -8.332],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.776000019148, 0.728999956916, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [81.398, 73.687], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-4.026, -2.427],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-3.916, 1.427],
                    [3.916, 1],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.809, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [29.863, 59.611], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-15.703, -2.855],
                    [15.703, 2.855],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.809, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [65.443, 64.845], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.215, -6.894],
                    [4.494, -0.14],
                    [0.215, 6.893],
                    [-4.493, 0.14],
                  ],
                  o: [
                    [0.215, 6.893],
                    [-4.492, 0.14],
                    [-0.215, -6.893],
                    [4.492, -0.14],
                  ],
                  v: [
                    [8.136, -0.254],
                    [0.389, 12.482],
                    [-8.135, 0.254],
                    [-0.388, -12.481],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.809, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [18.298, 61.976], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.215, -6.894],
                    [4.494, -0.14],
                    [0.215, 6.893],
                    [-4.494, 0.14],
                  ],
                  o: [
                    [0.214, 6.893],
                    [-4.492, 0.14],
                    [-0.214, -6.894],
                    [4.492, -0.14],
                  ],
                  v: [
                    [8.136, -0.254],
                    [0.389, 12.482],
                    [-8.136, 0.254],
                    [-0.389, -12.481],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.809, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [41.881, 62.102], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [11.817, -3.376],
                    [8.576, 11.597],
                    [-11.818, -5.065],
                    [6.753, -25.323],
                    [10.129, -20.258],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-12.775, -17.276],
                    [11.817, 5.065],
                    [0, 0],
                    [-10.13, 20.259],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.011, -10.129],
                    [-37.506, -11.817],
                    [-13.871, -33.764],
                    [30.022, -15.194],
                    [40.152, 10.129],
                    [26.646, 40.517],
                    [26.646, 18.57],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [50.531, 49.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [12.185, -3.046],
                    [10.662, -4.57],
                    [0, 0],
                    [0, -4.569],
                    [-10.662, 0],
                    [-4.57, 9.139],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-12.186, 3.046],
                    [-10.663, 4.569],
                    [0, 0],
                    [0, 4.57],
                    [10.663, 0],
                    [4.57, -9.139],
                    [0, 0],
                  ],
                  v: [
                    [25.894, -29.702],
                    [15.231, -2.285],
                    [-19.801, -6.854],
                    [-31.987, -11.424],
                    [-35.033, 12.947],
                    [-15.233, 29.702],
                    [30.463, 3.808],
                    [30.463, -29.702],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [48.059, 90.728], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-8.441, -28.699],
                    [0, 0],
                    [5.065, -5.064],
                    [0, 0],
                    [-8.221, 0.209],
                    [-3.571, 3.561],
                    [0, 0],
                    [-11.818, 27.011],
                    [25.323, -33.764],
                  ],
                  o: [
                    [0, 1.688],
                    [8.441, 28.7],
                    [0, 0],
                    [0, 0],
                    [2.448, 5.386],
                    [5.042, -0.128],
                    [0, 0],
                    [0, 0],
                    [11.817, -27.012],
                    [0, 0],
                  ],
                  v: [
                    [-32.076, -37.889],
                    [-32.076, 17.822],
                    [-8.441, 43.145],
                    [-15.194, 60.027],
                    [-13.575, 63.589],
                    [8.52, 71.444],
                    [21.902, 65.555],
                    [30.981, 54.804],
                    [28.7, -2.436],
                    [-30.388, -37.889],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.776000019148, 0.728999956916, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [50.165, 71.903], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 151.000006150356,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Body Outlines',
      parent: 19,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 47,
              s: [-2.61],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 101,
              s: [-0.3],
            },
            { t: 150.000006109625, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [377.123, 330, 0], ix: 2 },
        a: { a: 0, k: [183.221, 254.112, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [14.752, -41.757],
                    [-12.848, -17.25],
                    [-6.187, -17.25],
                    [-14.752, 1.785],
                    [2.38, 41.757],
                    [-10.945, 1.785],
                    [-2.38, -18.202],
                    [-7.139, -20.105],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [159.663, 50.81], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [5.409, -50.256],
                    [13.464, -18.75],
                    [2.995, -18.75],
                    [7.754, 2.188],
                    [-17.27, 50.257],
                    [13.464, 5.043],
                    [7.754, -16.847],
                    [17.27, -16.847],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [229, 57.545], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.903, -74.234],
                    [0, 0],
                    [0, 0],
                    [-10.469, 19.986],
                  ],
                  o: [
                    [0, 0],
                    [-1.903, 74.234],
                    [0, 0],
                    [0, 0],
                    [10.469, -19.986],
                  ],
                  v: [
                    [31.33, -127.089],
                    [18.159, -25.662],
                    [13.401, 127.089],
                    [-31.33, 123.283],
                    [-4.682, -104.655],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.8666666666666667, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [146.263, 134.885], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [58.335, -9.031],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-71.535, 11.074],
                    [0, 0],
                  ],
                  v: [
                    [-25.22, 49.013],
                    [25.148, 43.929],
                    [26.694, -46.321],
                    [-29.027, 12.848],
                    [-23.058, 43.006],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [235.801, 114.933], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 20, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [25.238, 17.318],
                    [-1.903, -44.731],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.904, 44.73],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.186, -129.91],
                    [-24.745, -45.682],
                    [-20.938, 129.91],
                    [26.648, 118.49],
                    [26.648, -86.607],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.8666666666666667, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [235.801, 132.064], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.903, -13.324],
                    [0, 0],
                    [-9.517, 7.614],
                    [8.566, 6.662],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.904, 13.324],
                    [0, 0],
                    [9.518, -7.613],
                    [-8.565, -6.662],
                    [0, 0],
                  ],
                  v: [
                    [-11.42, -114.207],
                    [-58.054, -87.559],
                    [-58.054, 110.399],
                    [39.021, 110.399],
                    [51.393, -106.593],
                    [11.422, -118.013],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [200.111, 118.264], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.9411764705882353, 0.6784313725490196, 0.7098039215686275, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [9.767, 139.677], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.613, -25.697],
                    [-18.083, 20.938],
                  ],
                  o: [
                    [-1.082, 3.65],
                    [29.872, -34.589],
                  ],
                  v: [
                    [-58.241, -0.559],
                    [71.193, -5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [183.167, 232.078], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.469, -34.262],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-46.634, 16.892],
                    [-40.924, 17.37],
                    [46.635, 17.37],
                    [57.104, -3.094],
                    [-4.759, -12.611],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.0196078431372549, 0.8509803921568627, 0.7686274509803922, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [182.029, 238.419], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 151.000006150356,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'R_Hand Outlines',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 35,
              s: [-6.479],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 72,
              s: [0.898],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 115,
              s: [-6.116],
            },
            { t: 150.000006109625, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [174.722, 9.525, 0], ix: 2 },
        a: { a: 0, k: [203.274, 2.387, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [6.661, 0.952],
                    [1.903, 0],
                    [7.614, -5.71],
                    [-2.855, -3.806],
                    [-13.324, -1.904],
                  ],
                  o: [
                    [-0.952, 0],
                    [-6.663, -0.952],
                    [-1.904, 0],
                    [0, 0],
                    [2.856, 3.807],
                    [0, 0],
                  ],
                  v: [
                    [29.504, 8.089],
                    [18.083, -5.234],
                    [-3.807, -5.234],
                    [-29.504, -9.993],
                    [-6.663, 0.476],
                    [22.841, 15.703],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.776000019148, 0.728999956916, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [29.753, 43.078], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.172, 5.534],
                    [0.418, -0.722],
                    [-5.198, -4.548],
                  ],
                  o: [
                    [0, 0],
                    [-0.173, -0.816],
                    [-1.728, 2.992],
                    [7.614, 6.662],
                  ],
                  v: [
                    [6.469, 3.146],
                    [-1.163, -9.185],
                    [-2.842, -9.457],
                    [-1.271, 3.518],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.776000019148, 0.728999956916, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [44.349, 35.277], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [12.372, -9.517],
                    [4.759, 0.952],
                    [6.662, 2.855],
                    [4.759, -0.952],
                    [-17.131, -10.47],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-12.372, 9.517],
                    [-4.758, -0.951],
                    [0, 0],
                    [0, 0],
                    [17.131, 10.468],
                    [0, 0],
                  ],
                  v: [
                    [81.372, -76.138],
                    [33.786, -59.006],
                    [-9.042, 15.228],
                    [-60.435, -38.068],
                    [-81.372, -12.372],
                    [-15.704, 65.669],
                    [42.351, 19.034],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.8666666666666667, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [124.926, 76.387], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 151.000006150356,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: 'Texts',
      parent: 19,
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [391.619, 313.51, 0], ix: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1080,
      h: 1080,
      ip: 0,
      op: 1949.0000793844,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'cahat box Outlines',
      parent: 19,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [378.31, 519.641, 0], ix: 2 },
        a: { a: 0, k: [101.608, 13.574, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.782],
                    [5.782, 0],
                    [0, 5.781],
                    [-5.781, 0],
                  ],
                  o: [
                    [0, 5.781],
                    [-5.781, 0],
                    [0, -5.782],
                    [5.782, 0],
                  ],
                  v: [
                    [10.469, 0],
                    [-0.001, 10.469],
                    [-10.469, 0],
                    [-0.001, -10.469],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [16.429, 13.574], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.358, 0],
                    [0, 0],
                    [0, 7.358],
                    [-7.359, 0],
                    [0, 0],
                    [0, -7.359],
                  ],
                  o: [
                    [0, 0],
                    [-7.359, 0],
                    [0, -7.359],
                    [0, 0],
                    [7.358, 0],
                    [0, 7.358],
                  ],
                  v: [
                    [88.035, 13.324],
                    [-88.034, 13.324],
                    [-101.358, 0],
                    [-88.034, -13.324],
                    [88.035, -13.324],
                    [101.358, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [101.608, 13.574], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 151.000006150356,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'stethoscope Outlines',
      parent: 8,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 60,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 89,
              s: [6.706],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 118,
              s: [-3.332],
            },
            { t: 150.000006109625, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [89.689, 96.581, 0], ix: 2 },
        a: { a: 0, k: [35.603, 50.499, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 41,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 48,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 51,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 54,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 56,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 58,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 125,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 127,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 130,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 133,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 136,
              s: [100, 100, 100],
            },
            { t: 143.000005824509, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.456, 0],
                    [0, 2.455],
                    [-2.456, 0],
                    [0, -2.456],
                  ],
                  o: [
                    [-2.456, 0],
                    [0, -2.456],
                    [2.456, 0],
                    [0, 2.455],
                  ],
                  v: [
                    [23.791, 6.67],
                    [19.344, 2.224],
                    [23.791, -2.224],
                    [28.237, 2.224],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [6.743, 0],
                    [0, 6.743],
                    [0, 0],
                    [-4.498, 3.394],
                    [-5.02, -0.777],
                    [0, 0],
                  ],
                  o: [
                    [-6.743, 0],
                    [0, 0],
                    [5.169, -1.444],
                    [0.696, 5.045],
                    [0, 0],
                    [0, 6.743],
                  ],
                  v: [
                    [9.782, 47.581],
                    [-2.445, 35.352],
                    [-2.445, 11.254],
                    [12.352, 3.816],
                    [22.011, 13.634],
                    [22.011, 35.352],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 6.085],
                    [6.341, 0.052],
                    [0, 5.898],
                    [4.737, 1.981],
                    [0, 0.214],
                    [2.456, 0],
                    [0, -2.456],
                    [-2.456, 0],
                    [-0.813, 0.883],
                    [0, -12.134],
                    [3.09, -5.131],
                    [0.63, -4.496],
                    [6.402, -1.653],
                    [0, 12.571],
                    [-3.433, 1.042],
                    [-1.522, 0],
                    [0, 2.455],
                    [2.456, 0],
                    [0.006, -2.452],
                    [0, -15.201],
                    [-4.633, -4.812],
                    [-7.341, -2.051],
                    [0, 0],
                    [-8.214, 0],
                    [0, 8.214],
                    [0, 0],
                  ],
                  o: [
                    [0, -6.355],
                    [2.253, -4.166],
                    [0, -13.375],
                    [0.029, -0.206],
                    [0, -2.456],
                    [-2.456, 0],
                    [0, 2.455],
                    [1.295, 0],
                    [4.063, 1.615],
                    [0, 4.982],
                    [-4.249, 1.287],
                    [-4.332, 3.652],
                    [-18.775, -4.847],
                    [0, -13.691],
                    [0.802, 1.165],
                    [2.456, 0],
                    [0, -2.456],
                    [-2.453, 0],
                    [-4.053, 1.298],
                    [0, 10.792],
                    [5.473, 5.683],
                    [0, 0],
                    [0, 8.214],
                    [8.214, 0],
                    [0, 0],
                    [5.968, -0.456],
                  ],
                  v: [
                    [35.352, 2.224],
                    [23.885, -9.334],
                    [27.792, -24.457],
                    [14.833, -45.177],
                    [14.896, -45.802],
                    [10.45, -50.249],
                    [6.003, -45.802],
                    [10.45, -41.355],
                    [13.713, -42.801],
                    [25.125, -24.457],
                    [20.437, -8.841],
                    [12.354, 0.608],
                    [-3.779, 8.845],
                    [-32.685, -24.457],
                    [-19.895, -43.286],
                    [-16.231, -41.355],
                    [-11.784, -45.802],
                    [-16.231, -50.249],
                    [-20.677, -45.812],
                    [-35.352, -24.457],
                    [-25.196, -0.853],
                    [-5.114, 11.248],
                    [-5.114, 35.352],
                    [9.782, 50.249],
                    [24.68, 35.352],
                    [24.68, 13.74],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [35.602, 50.499], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 41.0000016699642,
      op: 144.00000586524,
      st: 48.0000019550801,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Circle Outlines 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 15, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [754.3712962962962, 698.0796296296297, 0], ix: 2 },
        a: { a: 0, k: [91.729, 91.726, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 34,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 41,
              s: [101.85185185185186, 101.85185185185186, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 44,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 47,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 49,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 51,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 132,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 134,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 137,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 140,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 143,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            { t: 150.000006109625, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -42.304],
                    [42.304, 0],
                    [0, 42.304],
                    [-42.303, 0],
                  ],
                  o: [
                    [0, 42.304],
                    [-42.303, 0],
                    [0, -42.304],
                    [42.304, 0],
                  ],
                  v: [
                    [76.598, -0.002],
                    [-0.001, 76.596],
                    [-76.598, -0.002],
                    [-0.001, -76.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.027450980392, 0.027450980392, 0.027450980392, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [41.77, 0],
                    [0, -41.769],
                    [-41.77, 0],
                    [0, 41.77],
                  ],
                  o: [
                    [-41.77, 0],
                    [0, 41.77],
                    [41.77, 0],
                    [0, -41.769],
                  ],
                  v: [
                    [0.001, -75.752],
                    [-75.752, 0],
                    [0.001, 75.752],
                    [75.751, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [42.702, 0],
                    [0, 42.703],
                    [-42.703, 0],
                    [0, -42.702],
                  ],
                  o: [
                    [-42.703, 0],
                    [0, -42.702],
                    [42.702, 0],
                    [0, 42.703],
                  ],
                  v: [
                    [0.001, 77.443],
                    [-77.443, 0],
                    [0.001, -77.443],
                    [77.443, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.724], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -50.66],
                    [50.661, 0],
                    [0, 50.66],
                    [-50.66, 0],
                  ],
                  o: [
                    [0, 50.66],
                    [-50.66, 0],
                    [0, -50.66],
                    [50.661, 0],
                  ],
                  v: [
                    [91.728, -0.002],
                    [-0.001, 91.727],
                    [-91.729, -0.002],
                    [-0.001, -91.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 70, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 34.0000013848484,
      op: 151.000006150356,
      st: 50.0000020365418,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'DNA Outlines',
      parent: 10,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 50,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 88,
              s: [14.675],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 120,
              s: [-13.298],
            },
            { t: 145.000005905971, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [92.721, 89.926, 0], ix: 2 },
        a: { a: 0, k: [52.719, 52.72, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 34,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 41,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 44,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 47,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 49,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 51,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 120,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 122,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 125,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 128,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 131,
              s: [100, 100, 100],
            },
            { t: 138.000005620855, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.714, -12.105],
                    [-13.002, -10.709],
                    [11.713, 12.105],
                    [13.002, 10.709],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [35.422, 69.065], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.755, 0.68],
                    [0.978, 5.129],
                    [0, 0],
                    [0.015, 0.005],
                  ],
                  o: [
                    [-0.501, -1.245],
                    [0, 0],
                    [-5.67, -1.168],
                    [-0.184, -0.092],
                  ],
                  v: [
                    [27.075, -30.402],
                    [23.88, -41.249],
                    [40.534, -25.926],
                    [30.132, -29.082],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-3.678, -1.279],
                    [-0.232, -6.29],
                  ],
                  o: [
                    [5.428, -0.48],
                    [1.01, 2.338],
                    [0, 0],
                  ],
                  v: [
                    [9.611, -29.574],
                    [24.263, -27.356],
                    [27.754, -12.827],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.763, -0.689],
                    [0, 0],
                    [-1.44, 1.737],
                    [0, 0],
                    [0.11, -0.132],
                  ],
                  o: [
                    [0, 0],
                    [0.377, -2.177],
                    [0, 0],
                    [-0.103, 0.133],
                    [-1.328, 1.595],
                  ],
                  v: [
                    [19.32, 3.28],
                    [-4.032, -18.462],
                    [-1.4, -24.424],
                    [24.305, -0.491],
                    [24.017, -0.082],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.318, 1.724],
                    [-0.286, 7.197],
                    [0, 0],
                  ],
                  o: [
                    [-1.19, -2.426],
                    [0, 0],
                    [-5.836, 1.266],
                  ],
                  v: [
                    [0.142, 1.361],
                    [-4.28, -16.092],
                    [17.21, 3.915],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.841, -4.449],
                    [0, 0],
                    [-5.553, -2.301],
                    [-1.618, -0.704],
                  ],
                  o: [
                    [0, 0],
                    [4.515, -0.785],
                    [0.354, 0.189],
                    [2.194, 5.493],
                  ],
                  v: [
                    [-0.664, 19.438],
                    [-21.066, 0.605],
                    [-5.758, 2.855],
                    [-2.676, 4.326],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 5,
              ty: 'sh',
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.76, -1.825],
                    [2.258, -0.818],
                    [0, 0],
                    [-1.728, 1.667],
                    [-2.495, 0.762],
                    [0, 0],
                  ],
                  o: [
                    [-1.694, 1.756],
                    [0, 0],
                    [0.82, -2.216],
                    [1.864, -1.798],
                    [0, 0],
                    [-0.766, 2.433],
                  ],
                  v: [
                    [-4.973, 27.972],
                    [-10.987, 31.847],
                    [-33.701, 10.879],
                    [-29.874, 4.973],
                    [-23.287, 1.141],
                    [-1.189, 21.54],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 6',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 6,
              ty: 'sh',
              ix: 7,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.368, 1.264],
                    [2.669, 0.717],
                    [0.729, 1.945],
                    [-0.767, 3.314],
                    [0, 0],
                  ],
                  o: [
                    [-2.888, -1.083],
                    [-0.558, -1.857],
                    [-1.292, -3.446],
                    [0, 0],
                    [-3.255, 0.708],
                  ],
                  v: [
                    [-23.283, 31.676],
                    [-31.654, 28.995],
                    [-33.578, 23.283],
                    [-34.293, 12.918],
                    [-13.133, 32.452],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 7',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 7,
              ty: 'sh',
              ix: 8,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.454, -2.631],
                    [0, 0],
                    [-3.684, -0.895],
                  ],
                  o: [
                    [0, 0],
                    [2.768, 0.367],
                    [0.86, 3.144],
                  ],
                  v: [
                    [-32.778, 40.868],
                    [-44.505, 30.313],
                    [-34.716, 32.149],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 8',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 8,
              ty: 'sh',
              ix: 9,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.544, 0.526],
                    [0, 0],
                    [1.486, -2.525],
                    [0, 0],
                    [-0.053, 0.044],
                  ],
                  o: [
                    [0, 0],
                    [-0.172, 2.85],
                    [0, 0],
                    [0.052, -0.045],
                    [2.122, -1.768],
                  ],
                  v: [
                    [7.194, -29.22],
                    [27.72, -10.273],
                    [25.409, -2.064],
                    [-0.068, -25.783],
                    [0.081, -25.921],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 9',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 9,
              ty: 'sh',
              ix: 10,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-8.951, 1.786],
                    [0, 0],
                    [2.655, 0.331],
                    [0, 0],
                    [-0.463, 2.315],
                    [0, 0],
                    [-1.642, -4.771],
                    [7.546, -6.283],
                    [-2.827, -7.159],
                    [6.573, -6.34],
                    [-2.998, -7.995],
                    [-0.36, -1.097],
                    [0.394, 0],
                    [0, 0],
                    [-2.9, -0.274],
                    [0, 0],
                    [0, -0.065],
                    [0, 0],
                    [0.496, 4.222],
                    [0, 0],
                    [0, 0],
                    [1.111, 4.445],
                    [-1.997, -0.749],
                    [-2.592, 0],
                    [-3.807, 3.947],
                    [3.005, 9.752],
                    [-6.554, 7.862],
                    [-0.439, 3.854],
                    [0, 0],
                    [0, 0],
                    [1.382, 3.971],
                  ],
                  o: [
                    [0, 0],
                    [-2.406, 0.48],
                    [0, 0],
                    [-0.3, -2.56],
                    [0, 0],
                    [-1.407, 7.034],
                    [-6.969, -1.955],
                    [-8.985, 7.49],
                    [-10.927, -4.296],
                    [-5.807, 5.601],
                    [0.421, 1.122],
                    [-9.341, -2.051],
                    [0, 0],
                    [0.047, 0],
                    [0, 0],
                    [0.727, 5.192],
                    [0, 0],
                    [0, -0.244],
                    [0, 0],
                    [0, 0],
                    [-0.387, -3.17],
                    [1.906, 0.561],
                    [2.559, 0.96],
                    [5.503, 0],
                    [5.762, -5.973],
                    [7.437, 2.437],
                    [2.758, -3.311],
                    [0, 0],
                    [0, 0],
                    [0.527, -6.056],
                    [2.712, 1.091],
                  ],
                  v: [
                    [52.469, -21.715],
                    [51.721, -25.444],
                    [43.962, -25.358],
                    [23.416, -44.26],
                    [23.541, -51.723],
                    [19.813, -52.47],
                    [22.451, -31.942],
                    [-2.356, -28.842],
                    [-5.035, -0.937],
                    [-32.514, 2.236],
                    [-37.139, 24.618],
                    [-35.979, 27.946],
                    [-52.469, 25.85],
                    [-52.469, 29.654],
                    [-47.765, 29.939],
                    [-32.305, 43.853],
                    [-31.556, 52.47],
                    [-27.754, 52.467],
                    [-28.263, 45.324],
                    [-28.068, 45.107],
                    [-28.321, 44.879],
                    [-30.482, 33.285],
                    [-24.619, 35.236],
                    [-16.827, 36.663],
                    [-2.237, 30.611],
                    [1.983, 6.098],
                    [26.94, 2.355],
                    [31.441, -8.65],
                    [31.827, -9.067],
                    [31.514, -9.355],
                    [29.123, -25.392],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 10',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [52.719, 52.72], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 11,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 34.0000013848484,
      op: 139.000005661586,
      st: 38.0000015477717,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Circle Outlines 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 15, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [830.2972222222222, 397.6166666666666, 0], ix: 2 },
        a: { a: 0, k: [91.729, 91.726, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 27,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 34,
              s: [101.85185185185186, 101.85185185185186, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 37,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 40,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 42,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 44,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 127,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 129,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 132,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 135,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 138,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            { t: 145.000005905971, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -42.304],
                    [42.304, 0],
                    [0, 42.304],
                    [-42.303, 0],
                  ],
                  o: [
                    [0, 42.304],
                    [-42.303, 0],
                    [0, -42.304],
                    [42.304, 0],
                  ],
                  v: [
                    [76.598, -0.002],
                    [-0.001, 76.596],
                    [-76.598, -0.002],
                    [-0.001, -76.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.027450980392, 0.027450980392, 0.027450980392, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [41.77, 0],
                    [0, -41.769],
                    [-41.77, 0],
                    [0, 41.77],
                  ],
                  o: [
                    [-41.77, 0],
                    [0, 41.77],
                    [41.77, 0],
                    [0, -41.769],
                  ],
                  v: [
                    [0.001, -75.752],
                    [-75.752, 0],
                    [0.001, 75.752],
                    [75.751, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [42.702, 0],
                    [0, 42.703],
                    [-42.703, 0],
                    [0, -42.702],
                  ],
                  o: [
                    [-42.703, 0],
                    [0, -42.702],
                    [42.702, 0],
                    [0, 42.703],
                  ],
                  v: [
                    [0.001, 77.443],
                    [-77.443, 0],
                    [0.001, -77.443],
                    [77.443, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.724], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -50.66],
                    [50.661, 0],
                    [0, 50.66],
                    [-50.66, 0],
                  ],
                  o: [
                    [0, 50.66],
                    [-50.66, 0],
                    [0, -50.66],
                    [50.661, 0],
                  ],
                  v: [
                    [91.728, -0.002],
                    [-0.001, 91.727],
                    [-91.729, -0.002],
                    [-0.001, -91.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 70, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 27.0000010997325,
      op: 146.000005946702,
      st: 40.0000016292334,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Heart Outlines',
      parent: 12,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 40,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 70,
              s: [7.079],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 106,
              s: [-3.325],
            },
            { t: 140.000005702317, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [92.06, 139.985, 0], ix: 2 },
        a: { a: 0, k: [46.347, 86.299, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 27,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 34,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 37,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 40,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 42,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 44,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 115,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 117,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 120,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 123,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 126,
              s: [100, 100, 100],
            },
            { t: 133.000005417201, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-12.08, 22.106],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [12.135, 22.204],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [11.249, -31.61],
                    [-0.747, 16.373],
                    [-14.808, -19.952],
                    [-22.225, -6.973],
                    [-40.044, -6.973],
                    [-0.036, 31.61],
                    [40.044, -5.863],
                    [19.063, -5.863],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [46.382, 54.69], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 5.47],
                    [14.564, 0],
                    [4.806, -5.584],
                    [7.546, 0],
                    [-0.573, -15.999],
                    [-2.084, -4.676],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.195, -4.969],
                    [0, -16.01],
                    [-7.546, 0],
                    [-4.807, -5.584],
                    [-14.563, 0],
                    [0.181, 5.023],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-13.808, -3.522],
                    [-1.222, 28.992],
                    [11.209, -20.734],
                    [22.268, 15.698],
                    [42.289, 15.698],
                    [45.81, -0.004],
                    [19.44, -28.992],
                    [0.287, -19.918],
                    [-18.867, -28.992],
                    [-45.237, -0.004],
                    [-41.648, 14.588],
                    [-24.158, 14.588],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [46.059, 29.242], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 27.0000010997325,
      op: 134.000005457932,
      st: 28.0000011404634,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Circle Outlines 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 15, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [674.7416666666667, 169.83888888888887, 0], ix: 2 },
        a: { a: 0, k: [91.729, 91.726, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 20,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 27,
              s: [101.85185185185186, 101.85185185185186, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 35,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 37,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 122,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 124,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 127,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 130,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 133,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            { t: 140.000005702317, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -42.304],
                    [42.304, 0],
                    [0, 42.304],
                    [-42.303, 0],
                  ],
                  o: [
                    [0, 42.304],
                    [-42.303, 0],
                    [0, -42.304],
                    [42.304, 0],
                  ],
                  v: [
                    [76.598, -0.002],
                    [-0.001, 76.596],
                    [-76.598, -0.002],
                    [-0.001, -76.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.027450980392, 0.027450980392, 0.027450980392, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [41.77, 0],
                    [0, -41.769],
                    [-41.77, 0],
                    [0, 41.77],
                  ],
                  o: [
                    [-41.77, 0],
                    [0, 41.77],
                    [41.77, 0],
                    [0, -41.769],
                  ],
                  v: [
                    [0.001, -75.752],
                    [-75.752, 0],
                    [0.001, 75.752],
                    [75.751, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [42.702, 0],
                    [0, 42.703],
                    [-42.703, 0],
                    [0, -42.702],
                  ],
                  o: [
                    [-42.703, 0],
                    [0, -42.702],
                    [42.702, 0],
                    [0, 42.703],
                  ],
                  v: [
                    [0.001, 77.443],
                    [-77.443, 0],
                    [0.001, -77.443],
                    [77.443, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.724], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -50.66],
                    [50.661, 0],
                    [0, 50.66],
                    [-50.66, 0],
                  ],
                  o: [
                    [0, 50.66],
                    [-50.66, 0],
                    [0, -50.66],
                    [50.661, 0],
                  ],
                  v: [
                    [91.728, -0.002],
                    [-0.001, 91.727],
                    [-91.729, -0.002],
                    [-0.001, -91.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 70, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 20.0000008146167,
      op: 141.000005743048,
      st: 30.0000012219251,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Tablet Outlines',
      parent: 14,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 30,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 65,
              s: [-29.002],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 102,
              s: [-3.806],
            },
            { t: 135.000005498663, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [90.953, 87.04, 0], ix: 2 },
        a: { a: 0, k: [45.903, 37.253, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 20,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 27,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 35,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 37,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 110,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 112,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 115,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 118,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 121,
              s: [100, 100, 100],
            },
            { t: 128.000005213547, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.005, 1.517],
                    [-1.517, -1.005],
                    [1.005, -1.517],
                    [1.517, 1.005],
                  ],
                  o: [
                    [1.005, -1.517],
                    [1.516, 1.006],
                    [-1.006, 1.517],
                    [-1.517, -1.005],
                  ],
                  v: [
                    [-0.011, 8.484],
                    [4.557, 7.558],
                    [5.484, 12.126],
                    [0.916, 13.053],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.006, 1.517],
                    [-1.517, -1.004],
                    [1.005, -1.517],
                    [1.516, 1.006],
                  ],
                  o: [
                    [1.005, -1.517],
                    [1.518, 1.006],
                    [-1.005, 1.517],
                    [-1.517, -1.005],
                  ],
                  v: [
                    [-18.325, -3.65],
                    [-13.758, -4.579],
                    [-12.831, -0.011],
                    [-17.398, 0.916],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.005, 1.517],
                    [-1.517, -1.005],
                    [1.005, -1.517],
                    [1.517, 1.005],
                  ],
                  o: [
                    [1.006, -1.517],
                    [1.517, 1.005],
                    [-1.005, 1.517],
                    [-1.517, -1.006],
                  ],
                  v: [
                    [-2.483, -3.696],
                    [2.085, -4.623],
                    [3.012, -0.054],
                    [-1.556, 0.872],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.006, 1.517],
                    [-1.518, -1.005],
                    [1.005, -1.517],
                    [1.518, 1.005],
                  ],
                  o: [
                    [1.005, -1.517],
                    [1.516, 1.006],
                    [-1.005, 1.517],
                    [-1.516, -1.006],
                  ],
                  v: [
                    [12.147, -1.908],
                    [16.715, -2.835],
                    [17.641, 1.733],
                    [13.073, 2.66],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.006, 1.517],
                    [-1.516, -1.005],
                    [1.005, -1.517],
                    [1.517, 1.006],
                  ],
                  o: [
                    [1.005, -1.517],
                    [1.519, 1.005],
                    [-1.006, 1.517],
                    [-1.517, -1.005],
                  ],
                  v: [
                    [-6.785, -17.089],
                    [-2.219, -18.016],
                    [-1.291, -13.449],
                    [-5.859, -12.522],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 5,
              ty: 'sh',
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [5.362, -8.091],
                    [-8.092, -5.362],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-8.092, -5.362],
                    [-5.361, 8.092],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [26.765, -4.082],
                    [2.956, -19.858],
                    [-21.404, -14.915],
                    [-16.46, 9.444],
                    [7.348, 25.22],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 6',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [27.015, 25.471], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 7,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-5.362, 8.092],
                    [8.092, 5.362],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [8.092, 5.362],
                    [5.362, -8.092],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.956, 19.858],
                    [21.404, 14.915],
                    [16.461, -9.445],
                    [-7.349, -25.221],
                    [-26.766, 4.083],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [64.791, 49.036], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 20.0000008146167,
      op: 129.000005254278,
      st: 18.000000733155,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Circle Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 15, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [354.3712962962963, 143.91296296296295, 0], ix: 2 },
        a: { a: 0, k: [91.729, 91.726, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 13,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 20,
              s: [101.85185185185186, 101.85185185185186, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 23,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 26,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 28,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 117,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 119,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 122,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 125,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 128,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            { t: 135.000005498663, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -42.304],
                    [42.304, 0],
                    [0, 42.304],
                    [-42.303, 0],
                  ],
                  o: [
                    [0, 42.304],
                    [-42.303, 0],
                    [0, -42.304],
                    [42.304, 0],
                  ],
                  v: [
                    [76.598, -0.002],
                    [-0.001, 76.596],
                    [-76.598, -0.002],
                    [-0.001, -76.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.027450980392, 0.027450980392, 0.027450980392, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [41.77, 0],
                    [0, -41.769],
                    [-41.77, 0],
                    [0, 41.77],
                  ],
                  o: [
                    [-41.77, 0],
                    [0, 41.77],
                    [41.77, 0],
                    [0, -41.769],
                  ],
                  v: [
                    [0.001, -75.752],
                    [-75.752, 0],
                    [0.001, 75.752],
                    [75.751, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [42.702, 0],
                    [0, 42.703],
                    [-42.703, 0],
                    [0, -42.702],
                  ],
                  o: [
                    [-42.703, 0],
                    [0, -42.702],
                    [42.702, 0],
                    [0, 42.703],
                  ],
                  v: [
                    [0.001, 77.443],
                    [-77.443, 0],
                    [0.001, -77.443],
                    [77.443, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.724], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -50.66],
                    [50.661, 0],
                    [0, 50.66],
                    [-50.66, 0],
                  ],
                  o: [
                    [0, 50.66],
                    [-50.66, 0],
                    [0, -50.66],
                    [50.661, 0],
                  ],
                  v: [
                    [91.728, -0.002],
                    [-0.001, 91.727],
                    [-91.729, -0.002],
                    [-0.001, -91.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 70, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 13.0000005295009,
      op: 136.000005539394,
      st: 20.0000008146167,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Briefcase Outlines',
      parent: 16,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 20,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 50,
              s: [-3.412],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 88,
              s: [5.114],
            },
            { t: 130.000005295009, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [93.301, 118.366, 0], ix: 2 },
        a: { a: 0, k: [38.329, 61.178, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 13,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 20,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 23,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 26,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 28,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 105,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 107,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 110,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 113,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 116,
              s: [100, 100, 100],
            },
            { t: 123.000005009893, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.441, 0],
                    [0, 0],
                    [0, -3.389],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.939, 0],
                    [0, 0],
                    [0, -4.922],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, -3.389],
                    [0, 0],
                    [-0.44, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -4.922],
                    [0, 0],
                    [2.939, 0],
                    [0, 0],
                  ],
                  v: [
                    [17.347, 5.923],
                    [13.962, 5.923],
                    [13.962, 2.712],
                    [12.192, -2.538],
                    [-12.193, -2.538],
                    [-13.963, 2.712],
                    [-13.963, 5.923],
                    [-17.347, 5.923],
                    [-17.347, 2.712],
                    [-12.193, -5.923],
                    [12.192, -5.923],
                    [17.347, 2.712],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [38.329, 6.174], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.478, 0],
                    [0, 7.478],
                    [-7.478, 0],
                    [0, -7.477],
                  ],
                  o: [
                    [-7.478, 0],
                    [0, -7.477],
                    [7.478, 0],
                    [0, 7.478],
                  ],
                  v: [
                    [-0.846, 12.693],
                    [-14.386, -0.847],
                    [-0.846, -14.386],
                    [12.693, -0.847],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.611, 0],
                    [0, 0],
                    [0, -3.611],
                    [0, 0],
                    [-3.611, 0],
                    [0, 0],
                    [0, 3.611],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.611, 0],
                    [0, 0],
                    [0, 3.611],
                    [0, 0],
                    [3.611, 0],
                    [0, 0],
                    [0, -3.611],
                  ],
                  v: [
                    [31.541, -26.233],
                    [-31.541, -26.233],
                    [-38.079, -19.694],
                    [-38.079, 19.694],
                    [-31.541, 26.233],
                    [31.541, 26.233],
                    [38.079, 19.694],
                    [38.079, -19.694],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [38.329, 34.945], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [8.462, -2.82],
                    [2.82, -2.82],
                    [2.82, -8.462],
                    [-2.821, -8.462],
                    [-2.821, -2.82],
                    [-8.462, -2.82],
                    [-8.462, 2.821],
                    [-2.821, 2.821],
                    [-2.821, 8.462],
                    [2.82, 8.462],
                    [2.82, 2.821],
                    [8.462, 2.821],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [36.637, 34.098], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 13.0000005295009,
      op: 124.000005050624,
      st: 8.00000032584668,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Circle Outlines 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 15, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [159.92685185185186, 347.15370370370374, 0], ix: 2 },
        a: { a: 0, k: [91.729, 91.726, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 6,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 13,
              s: [101.85185185185186, 101.85185185185186, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 16,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 19,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 21,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 23,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 112,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 114,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 117,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 120,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 123,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            { t: 130.000005295009, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -42.304],
                    [42.304, 0],
                    [0, 42.304],
                    [-42.303, 0],
                  ],
                  o: [
                    [0, 42.304],
                    [-42.303, 0],
                    [0, -42.304],
                    [42.304, 0],
                  ],
                  v: [
                    [76.598, -0.002],
                    [-0.001, 76.596],
                    [-76.598, -0.002],
                    [-0.001, -76.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.027450980392, 0.027450980392, 0.027450980392, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [41.77, 0],
                    [0, -41.769],
                    [-41.77, 0],
                    [0, 41.77],
                  ],
                  o: [
                    [-41.77, 0],
                    [0, 41.77],
                    [41.77, 0],
                    [0, -41.769],
                  ],
                  v: [
                    [0.001, -75.752],
                    [-75.752, 0],
                    [0.001, 75.752],
                    [75.751, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [42.702, 0],
                    [0, 42.703],
                    [-42.703, 0],
                    [0, -42.702],
                  ],
                  o: [
                    [-42.703, 0],
                    [0, -42.702],
                    [42.702, 0],
                    [0, 42.703],
                  ],
                  v: [
                    [0.001, 77.443],
                    [-77.443, 0],
                    [0.001, -77.443],
                    [77.443, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.724], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -50.66],
                    [50.661, 0],
                    [0, 50.66],
                    [-50.66, 0],
                  ],
                  o: [
                    [0, 50.66],
                    [-50.66, 0],
                    [0, -50.66],
                    [50.661, 0],
                  ],
                  v: [
                    [91.728, -0.002],
                    [-0.001, 91.727],
                    [-91.729, -0.002],
                    [-0.001, -91.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 70, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 6.00000024438501,
      op: 131.000005335739,
      st: 10.0000004073083,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Injection Outlines',
      parent: 18,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 10,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 37,
              s: [-9.777],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 77,
              s: [6.974],
            },
            { t: 125.000005091354, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [55.721, 124.926, 0], ix: 2 },
        a: { a: 0, k: [0.25, 67.538, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 6,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 13,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 16,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 19,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 21,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 23,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 101,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 103,
              s: [98, 98, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 106,
              s: [105, 105, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 109,
              s: [95, 95, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 112,
              s: [100, 100, 100],
            },
            { t: 119.000004846969, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-3.688, 8.821],
                    [-9.494, 1.132],
                    [3.688, -8.821],
                    [9.494, -1.131],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [67.053, 16.67], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.458, 0.606],
                    [0, 0],
                    [-0.607, 0.458],
                    [-0.458, -0.606],
                    [0, 0],
                    [0.607, -0.458],
                  ],
                  o: [
                    [0, 0],
                    [-0.459, -0.607],
                    [0.606, -0.458],
                    [0, 0],
                    [0.458, 0.607],
                    [-0.607, 0.458],
                  ],
                  v: [
                    [6.367, 10.715],
                    [-8.563, -9.057],
                    [-8.294, -10.986],
                    [-6.366, -10.717],
                    [8.563, 9.056],
                    [8.294, 10.986],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [73.644, 11.694], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.458, 0.606],
                    [0, 0],
                    [-0.607, 0.458],
                    [-0.458, -0.606],
                    [0, 0],
                    [0.607, -0.458],
                  ],
                  o: [
                    [0, 0],
                    [-0.459, -0.607],
                    [0.606, -0.458],
                    [0, 0],
                    [0.458, 0.606],
                    [-0.607, 0.458],
                  ],
                  v: [
                    [6.367, 10.716],
                    [-8.563, -9.058],
                    [-8.294, -10.986],
                    [-6.366, -10.717],
                    [8.563, 9.057],
                    [8.294, 10.985],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [58.264, 23.305], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.066, 0.358],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.121, -6.054],
                    [0, 0],
                    [-0.096, -0.335],
                  ],
                  v: [
                    [3.121, 2.507],
                    [-3.367, -2.943],
                    [3.367, 3.546],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [36.946, 33.244], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.663, -0.17],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0.968, 0.583],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.357, -0.195],
                    [1.166, 0.918],
                    [1.357, 0.775],
                    [-0.398, -0.918],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [47.021, 43.793], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.959, 3.366],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.054, -1.236],
                  ],
                  v: [
                    [1.584, -1.325],
                    [-5.149, -7.814],
                    [-6.936, -6.467],
                    [3.848, 7.814],
                    [6.936, 5.482],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [38.729, 38.115], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.968, 0.583],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.663, -0.17],
                  ],
                  v: [
                    [8.25, 5.778],
                    [5.161, 8.11],
                    [-5.621, -6.17],
                    [-3.836, -7.519],
                    [13.055, -20.27],
                    [23.837, -5.99],
                    [9.209, 5.055],
                    [10.964, 6.748],
                    [27.486, -5.726],
                    [14.215, -23.303],
                    [-24.234, 5.727],
                    [-27.486, 21.98],
                    [-10.963, 23.303],
                    [10.773, 6.891],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [37.414, 37.819], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.146, 4.973],
                    [-6.146, 4.973],
                    [5.316, -4.973],
                    [6.146, -3.876],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [6.396, 62.564], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 6.00000024438501,
      op: 120.0000048877,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Circle Outlines 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 15, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [196.96388888888887, 663.8203703703705, 0], ix: 2 },
        a: { a: 0, k: [91.729, 91.726, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 7,
              s: [101.85185185185186, 101.85185185185186, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 10,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 13,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 15,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 17,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 108,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 110,
              s: [90.74074074074075, 90.74074074074075, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 113,
              s: [97.22222222222223, 97.22222222222223, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 116,
              s: [87.96296296296296, 87.96296296296296, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 119,
              s: [92.5925925925926, 92.5925925925926, 100],
            },
            { t: 126.000005132085, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -42.304],
                    [42.304, 0],
                    [0, 42.304],
                    [-42.303, 0],
                  ],
                  o: [
                    [0, 42.304],
                    [-42.303, 0],
                    [0, -42.304],
                    [42.304, 0],
                  ],
                  v: [
                    [76.598, -0.002],
                    [-0.001, 76.596],
                    [-76.598, -0.002],
                    [-0.001, -76.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.027450980392, 0.027450980392, 0.027450980392, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [41.77, 0],
                    [0, -41.769],
                    [-41.77, 0],
                    [0, 41.77],
                  ],
                  o: [
                    [-41.77, 0],
                    [0, 41.77],
                    [41.77, 0],
                    [0, -41.769],
                  ],
                  v: [
                    [0.001, -75.752],
                    [-75.752, 0],
                    [0.001, 75.752],
                    [75.751, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [42.702, 0],
                    [0, 42.703],
                    [-42.703, 0],
                    [0, -42.702],
                  ],
                  o: [
                    [-42.703, 0],
                    [0, -42.702],
                    [42.702, 0],
                    [0, 42.703],
                  ],
                  v: [
                    [0.001, 77.443],
                    [-77.443, 0],
                    [0.001, -77.443],
                    [77.443, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.724], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -50.66],
                    [50.661, 0],
                    [0, 50.66],
                    [-50.66, 0],
                  ],
                  o: [
                    [0, 50.66],
                    [-50.66, 0],
                    [0, -50.66],
                    [50.661, 0],
                  ],
                  v: [
                    [91.728, -0.002],
                    [-0.001, 91.727],
                    [-91.729, -0.002],
                    [-0.001, -91.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 70, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.728, 91.726], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 126.000005132085,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'Hand Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 43,
              s: [2],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 80,
              s: [-1],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 111,
              s: [1.5],
            },
            { t: 150.000006109625, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [303.1805555555556, 1058.8074074074075, 0], ix: 2 },
        a: { a: 0, k: [178.054, 917.006, 0], ix: 1 },
        s: { a: 0, k: [92.5925925925926, 92.5925925925926, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [6.785, -7.87],
                    [4.548, 3.92],
                    [-6.785, 7.87],
                    [-4.547, -3.92],
                  ],
                  o: [
                    [-6.784, 7.87],
                    [-4.546, -3.92],
                    [6.785, -7.87],
                    [4.547, 3.92],
                  ],
                  v: [
                    [9.14, 5.515],
                    [-11.379, 12.667],
                    [-7.327, -8.681],
                    [10.462, -12.667],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.987999949736, 0.764999988032, 0.795999983245, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [510.968, 454.106], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.562, -9.931],
                    [5.738, 4.947],
                    [-8.562, 9.931],
                    [-5.738, -4.947],
                  ],
                  o: [
                    [-8.562, 9.931],
                    [-5.738, -4.946],
                    [8.561, -9.931],
                    [5.737, 4.947],
                  ],
                  v: [
                    [11.534, 6.959],
                    [-14.358, 15.983],
                    [-9.245, -10.954],
                    [13.201, -15.983],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.987999949736, 0.764999988032, 0.795999983245, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [516.55, 395.635], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [9.211, -10.684],
                    [6.172, 5.322],
                    [-9.211, 10.684],
                    [-6.173, -5.322],
                  ],
                  o: [
                    [-9.211, 10.685],
                    [-6.174, -5.321],
                    [9.21, -10.684],
                    [6.173, 5.322],
                  ],
                  v: [
                    [12.409, 7.486],
                    [-15.445, 17.196],
                    [-9.945, -11.785],
                    [14.203, -17.195],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.987999949736, 0.764999988032, 0.795999983245, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [520.658, 319.509], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [27.703, -15.214],
                    [7.195, -27.287],
                    [-12.681, 19.278],
                  ],
                  o: [
                    [0, 0],
                    [-7.195, 27.287],
                    [12.682, -19.279],
                  ],
                  v: [
                    [6.187, -28.815],
                    [-31.796, 16.742],
                    [26.309, -0.472],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.987999949736, 0.663000009574, 0.666999966491, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [528.2, 438.068], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [30.377, -24.077],
                    [3.104, -34.47],
                    [-11.379, 25.91],
                  ],
                  o: [
                    [0, 0],
                    [-3.103, 34.469],
                    [11.378, -25.912],
                  ],
                  v: [
                    [-0.917, -36.633],
                    [-37.502, 26.24],
                    [29.227, -6.49],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.987999949736, 0.663000009574, 0.666999966491, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [534.432, 372.054], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [28.527, -31.122],
                    [-2.594, -37.605],
                    [-7.78, 29.825],
                  ],
                  o: [
                    [0, 0],
                    [2.592, 37.605],
                    [7.781, -29.824],
                  ],
                  v: [
                    [-7.131, -40.199],
                    [-35.659, 33.715],
                    [30.472, -12.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.987999949736, 0.663000009574, 0.666999966491, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [537.621, 289.677], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.781],
                    [5.782, 0],
                    [0, 5.782],
                    [-5.782, 0],
                  ],
                  o: [
                    [0, 5.782],
                    [-5.782, 0],
                    [0, -5.781],
                    [5.782, 0],
                  ],
                  v: [
                    [10.469, 0],
                    [0, 10.469],
                    [-10.469, 0],
                    [0, -10.469],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.486, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [380.689, 570.557], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.984313725490196, 0.984313725490196, 0.984313725490196, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [395.436, 378.31], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.984313725490196, 0.984313725490196, 0.984313725490196, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [380.689, 313.842], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.984313725490196, 0.984313725490196, 0.984313725490196, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [380.689, 288.405], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.94, 0],
                    [0, 0],
                    [0, 5.942],
                    [0, 0],
                    [-5.941, 0],
                    [0, 0],
                    [0, -5.942],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-5.941, 0],
                    [0, 0],
                    [0, -5.942],
                    [0, 0],
                    [5.94, 0],
                    [0, 0],
                    [0, 5.942],
                  ],
                  v: [
                    [122.008, 226],
                    [-122.008, 226],
                    [-132.765, 215.242],
                    [-132.765, -215.243],
                    [-122.008, -226.001],
                    [122.008, -226.001],
                    [132.765, -215.243],
                    [132.765, 215.242],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.984313725490196, 0.984313725490196, 0.984313725490196, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [380.689, 323.143], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.558, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -1.557],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.558, 0],
                    [0, 0],
                    [0, 1.557],
                  ],
                  v: [
                    [0.987, 19.51],
                    [-3.807, 19.51],
                    [-3.807, -19.51],
                    [0.987, -19.51],
                    [3.806, -16.69],
                    [3.806, 16.691],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [520.593, 312.641], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.205, 0],
                    [0, 0],
                    [0, 0],
                    [0, 4.205],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-4.205, 0],
                    [0, 0],
                    [0, -4.205],
                  ],
                  v: [
                    [3.807, -37.117],
                    [3.807, -37.117],
                    [3.807, 37.117],
                    [-3.807, 29.504],
                    [-3.807, -29.503],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [240.786, 252.206], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.204, 0],
                    [0, 0],
                    [0, 0],
                    [0, -4.205],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [4.204, 0],
                    [0, 0],
                    [0, 4.205],
                  ],
                  v: [
                    [-3.807, 37.117],
                    [-3.807, 37.117],
                    [-3.807, -37.117],
                    [3.806, -29.504],
                    [3.806, 29.503],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [520.593, 207.476], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [6.492, 0],
                    [0, 0],
                    [0, 6.493],
                    [0, 0],
                    [-6.492, 0],
                    [0, 0],
                    [0, -6.493],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-6.492, 0],
                    [0, 0],
                    [0, -6.493],
                    [0, 0],
                    [6.492, 0],
                    [0, 0],
                    [0, 6.493],
                  ],
                  v: [
                    [130.05, 252.682],
                    [-130.051, 252.682],
                    [-141.807, 240.925],
                    [-141.807, -240.927],
                    [-130.051, -252.683],
                    [130.05, -252.683],
                    [141.806, -240.927],
                    [141.806, 240.925],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.011764705882352941, 0.1568627450980392, 0.34901960784313724,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [380.69, 338.814], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-40.383, 54.466],
                    [32.87, -32.118],
                  ],
                  o: [
                    [0, 0],
                    [-32.869, 32.118],
                  ],
                  v: [
                    [52.249, -17.905],
                    [-19.38, -4.442],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.9215686274509803, 0.6627450980392157, 0.6627450980392157, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [501.653, 423.339], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-40.384, 54.466],
                    [32.869, -32.119],
                  ],
                  o: [
                    [0, 0],
                    [-32.869, 32.118],
                  ],
                  v: [
                    [52.389, -18.364],
                    [-19.52, -3.983],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.9215686274509803, 0.6627450980392157, 0.6627450980392157, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [508.456, 346.742], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-34.061, 65.525],
                    [29.824, -40.199],
                  ],
                  o: [
                    [0, 0],
                    [-29.825, 40.198],
                  ],
                  v: [
                    [52.344, -27.839],
                    [-22.519, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.9215686274509803, 0.6627450980392157, 0.6627450980392157, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [506.326, 265.04], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.838999968884, 0.501999978458, 0.522000002394, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [554.781, 173.581], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-39.208, -8.961],
                    [102.441, -79.1],
                  ],
                  o: [
                    [0, 0],
                    [45.386, 10.374],
                    [-102.441, 79.101],
                  ],
                  v: [
                    [-41.496, -6.542],
                    [76.507, -110.281],
                    [-19.451, 40.14],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.987999949736, 0.663000009574, 0.666999966491, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [494.181, 214.525], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-17.229, 58.838],
                    [-302.446, 47.006],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [4.866, 21.182],
                    [-1.742, 42.636],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-79.541, -111.843],
                    [55.653, 179.766],
                    [22.677, 235.071],
                    [-222.118, 137.841],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.838999968884, 0.501999978458, 0.522000002394, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [286.114, 733.647], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 21',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.838999968884, 0.501999978458, 0.522000002394, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.773, 431.653], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 22',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [57.056, 62.243],
                    [15.561, 29.825],
                    [-41.827, 76.271],
                    [-9.517, 12.373],
                    [-3.948, -51.978],
                    [-19.034, -9.518],
                    [-55.759, -60.946],
                    [43.877, -27.231],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-15.56, -29.825],
                    [48.537, -88.51],
                    [7.628, -9.916],
                    [5.711, 75.186],
                    [19.199, 9.599],
                    [55.76, 60.947],
                    [-81.299, 50.454],
                    [0, 0],
                  ],
                  v: [
                    [-224.777, 354.611],
                    [-83.208, 80.255],
                    [-128.779, -137.7],
                    [-86.903, -324.238],
                    [-35.511, -260.471],
                    [-54.545, -119.617],
                    [160.577, -141.486],
                    [133.347, 108.783],
                    [39.801, 385.066],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.987999949736, 0.663000009574, 0.666999966491, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [291.524, 519.34], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 23',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 151.000006150356,
      op: 151.000006150356,
      st: 0,
      bm: 0,
      hidden: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: 'Circle 2 Outlines 2',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [496.4527777777777, 476.8129629629629, 0], ix: 2 },
        a: { a: 0, k: [391.839, 391.839, 0], ix: 1 },
        s: { a: 0, k: [92.5925925925926, 92.5925925925926, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -216.269],
                    [216.269, 0],
                    [0, 216.269],
                    [-216.268, 0],
                  ],
                  o: [
                    [0, 216.269],
                    [-216.268, 0],
                    [0, -216.269],
                    [216.269, 0],
                  ],
                  v: [
                    [391.589, 0],
                    [0, 391.589],
                    [-391.589, 0],
                    [0, -391.589],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.984313725490196, 0.984313725490196, 0.984313725490196, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [391.839, 391.839], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 1949.0000793844,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 0,
      nm: 'Pre-comp 1',
      tt: 1,
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500.00000000000006, 500.00000000000006, 0], ix: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1 },
        s: { a: 0, k: [92.5925925925926, 92.5925925925926, 100], ix: 6 },
      },
      ao: 0,
      w: 1080,
      h: 1080,
      ip: 0,
      op: 1949.0000793844,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: 'Circle 2 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [496.4527777777777, 476.8129629629629, 0], ix: 2 },
        a: { a: 0, k: [391.839, 391.839, 0], ix: 1 },
        s: { a: 0, k: [92.5925925925926, 92.5925925925926, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -216.269],
                    [216.269, 0],
                    [0, 216.269],
                    [-216.268, 0],
                  ],
                  o: [
                    [0, 216.269],
                    [-216.268, 0],
                    [0, -216.269],
                    [216.269, 0],
                  ],
                  v: [
                    [391.589, 0],
                    [0, 391.589],
                    [-391.589, 0],
                    [0, -391.589],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.984313725490196, 0.984313725490196, 0.984313725490196, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [391.839, 391.839], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 1949.0000793844,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

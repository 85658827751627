import React from 'react';
import Lottie from 'react-lottie-player';

interface IModuleItem {
  title: string;
  description: string;
  image: any;
}

interface ICard {
  module: IModuleItem;
}

const Card: React.FC<ICard> = ({ module }) => {
  return (
    <div className="rounded-3xl bg-white p-6 w-full md:w-2/3 lg:w-1/4 flex flex-col justify-start items-center medical-card m-6 lg:m-0">
      <h2 className="text-lg lg:text-3xl color-blue">{module.title}</h2>
      <h2 className="text-sm lg:text-lg color-blue whitespace-pre-wrap mt-2">
        {module.description}
      </h2>
      <Lottie
        animationData={module.image}
        play
        loop
        className=" h-40 lg:h-60 "
      />
    </div>
  );
};

export default Card;
